import { createAction, props } from '@ngrx/store';
import { Field, FieldDynamicAttributes } from '@hmi/ui-component';

export const ShowLoader = createAction(
    '[page] Show loader'
);

export const HideLoader = createAction(
    '[page] Hide loader'
);

export const LoadPageConfigAndData = createAction(
    '[Page] Load Page Config And Data',
    props<{ segment: string, sharedData: any }>()
);

export const LoadPageConfigAndDataSuccess = createAction(
    '[Page] Load Page Config And Data Success',
    props<{ data: any, segment: string, sharedData: any }>()
);

export const LoadPageConfigAndDataFail = createAction(
    '[Page] Load Page Config And Data Fail',
    props<{ error: any }>()
);

export const ClearPageConfigAndData = createAction(
    '[Page] Clear Page Config And Data',
    props<{ payload: string }>()
);

export const LoadHeaderConfigAndData = createAction(
    '[Page] Load Header Config And Data',
    props<{ segment: string, sharedData: any }>()
);

export const LoadHeaderConfigAndDataSuccess = createAction(
    '[Page] Load Header Config And Data Success',
    props<{ data: any, segment: string, sharedData: any }>()
);

export const LoadHeaderConfigAndDataFail = createAction(
    '[Page] Load Header Config And Data Fail',
    props<{ error: any }>()
);

export const ClearHeaderConfigAndData = createAction(
    '[Page] Clear Header Config And Data',
    props<{ payload: string }>()
);

export const LoadNavConfigAndData = createAction(
    '[Page] Load Nav Config And Data',
    props<{ segment: string, sharedData: any }>()
);

export const LoadNavConfigAndDataSuccess = createAction(
    '[Page] Load Nav Config And Data Success',
    props<{ data: any, segment: string, sharedData: any }>()
);

export const LoadNavConfigAndDataFail = createAction(
    '[Page] Load Nav Config And Data Fail',
    props<{ error: any }>()
);

export const ClearNavConfigAndData = createAction(
    '[Page] Clear Nav Config And Data',
    props<{ payload: string }>()
);

export const LoadFooterConfigAndData = createAction(
    '[Page] Load Footer Config And Data',
    props<{ segment: string, sharedData: any }>()
);

export const LoadFooterConfigAndDataSuccess = createAction(
    '[Page] Load Footer Config And Data Success',
    props<{ data: any, segment: string, sharedData: any }>()
);

export const LoadFooterConfigAndDataFail = createAction(
    '[Page] Load Footer Config And Data Fail',
    props<{ error: any }>()
);

export const ClearFooterConfigAndData = createAction(
    '[Page] Clear Footer Config And Data',
    props<{ payload: string }>()
);

export const AddField = createAction(
    '[Page] Add a field',
    props<{field: Field<any>}>()
);

export const DeleteField = createAction(
    '[Page] Delete a field',
    props<{id: string}>()
);

export const UpdateField = createAction(
    '[Page] Update a field',
    props<{field: Field<any>}>()
);

export const UpdateFieldValue = createAction(
    '[Page] Update a fields value',
    props<{id: string, value: any, isValueOverride?: boolean}>()
);

export const UpdateExpression = createAction(
    '[Page] Evaluate a fields expression',
    props<{id: string, property: string}>()
);

export const CheckForUpdate = createAction(
    '[Page] Find expressions having this field and evaluate',
    props<{field?: FieldDynamicAttributes, sharedData: any, sharedDataAttributeList?: string[], id?: string}>()
);