import { Field } from './field';
import { UrlConfiguration } from '../../models/url-configuration';

export interface DropdownOption {
  name: string;
  label: string;
  value: string;
}

export class DropdownField extends Field<DropdownOption> {

  optionList: Array<DropdownOption>;
  optionsConfig?: UrlConfiguration;
  labelKey?: string;
  valueKey?: string;

  constructor(options: { 
    optionList: Array<DropdownOption>;
    type : string;
    value: string;
    optionsConfig?: any;
    labelKey?: string;
    valueKey?: string;
  }) {
    super(options);
    this.optionList = options.optionList;
    // this.setValue(options.value);
    this.optionsConfig = options.optionsConfig;
    this.labelKey = options.labelKey;
    this.valueKey = options.valueKey;
  }

  setValue(value: string) {
    if (!value) {
      this.value = null;
      return;
    }
    if (this.optionList && this.optionList.length) {
        let that = this;
        this.optionList.some(function(option) {
            if (option.name === value) {
                that.value = option;
                return true;
            }
        });
        console.log("Value of typeahead field is: " + this.value);
        //Looks like value is not set properly.
    } else {
        console.warn("Dropdown field:" + this.baseProperties.name + " doesn't have static options.");
        this.value = null;
    }
  }
}