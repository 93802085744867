import { Field } from "./field";

// TODO: should we extend any concrete Field type below?
export class DynamicField extends Field<any> {
    customAttributes: any = {};
    libraryComponent: string;
    constructor(options : {
        name?: string,
        label?: string,
        type?: string,
        id?: string,
        disabled?: string,
        disabledValue?: boolean,
        libraryComponent?: string,
        library?: string,
        customAttributes?: any
      } = {}) {
        super(options);
        this.libraryComponent = options.libraryComponent;
        if (options.customAttributes !== undefined && options.customAttributes !== null && Object.keys(options.customAttributes).length) {
            this.customAttributes = options.customAttributes;
        }
    }
}