import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { ViewComponent } from '@elements/view/view.component';
import { EventAction } from '@lib-service/event.action';
import { FieldDynamicAttributes } from '../../form/interfaces/field-dynamic-attributes';
import { Router } from '@angular/router';

@Component({
  selector: 'hmi-nav-menu',
  templateUrl: './nav-menu.component.html',
  styleUrls: ['./nav-menu.component.scss']
})
export class NavMenuComponent extends ViewComponent implements OnInit {
  @Input() fieldObj: any;
  @Input() dynamicAttributes: FieldDynamicAttributes;
  @Input() editMode: boolean;
  currentRoute: string;
  
  constructor(eventAction: EventAction,
    private router: Router) {
    super(eventAction);
  }

  ngOnInit(): void { 
    this.fieldObj;
    this.currentRoute = this.router.url.split('?')[0] + "/";
  }
  public eventAction(event, fieldObj) {
    /**
     * Sequence - First actions will get executed, then redirection will happen
     * If we are showing timer, navigate is not applicable. So it is in end.
     */
    super.eventAction(event, fieldObj);
    if (this.fieldObj.href && this.fieldObj.href.length) {
      return;
    }
  }
  sendValueToParent(newFieldObj) {
    this._dataChange.emit(newFieldObj);
  }

}
