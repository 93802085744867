import { Field } from './field';

export class TabElement extends Field<string> {

  defaultActive: boolean;
  

  constructor(options: {
    id: string;
    label: string;
    type: string;
    css: any;
    defaultActive?: boolean;
  }) {
    super(options);
    this.defaultActive = options.defaultActive;
    
  }
}