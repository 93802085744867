import { TOOLTIP_PLACEMENT } from '@lib-model/constants';
import { FieldType } from './fieldType';

export class BaseProperties {
  inputType: string;
  formName?: string;
  name: string;
  id: string;
  type: string;
  label: string;
  library: string;
  tooltipPlacement: TOOLTIP_PLACEMENT;
  tooltip: string;

  constructor(
    name?: string,
    id?: string,
    label?: string,
    type?: string,
    formName?: string,
    library?: string,
    tooltipPlacement?: TOOLTIP_PLACEMENT,
    tooltip?: string
  ) {
    this.formName = formName;
    this.id = id;
    this.label = label;
    this.name = name;
    this.type = type;
    if (library === undefined || library === null || library === '') {
      this.library = null;
    } else {
      this.library = library;
    }
    this.tooltipPlacement = tooltipPlacement || 'right';
    this.tooltip = tooltip;
  }
}
