export class ExpressionProperties {

    disabled: string;
    readOnly: string
    required: string;
    visible: string;
    disabledValue: (string | boolean);
    readOnlyValue: (string | boolean);
    requiredValue: (string | boolean);
    visibleValue: (string | boolean);
  constructor(
    disabled?: string,
    readOnly?: string,
    required?: string,
    visible?: string,
    disabledValue?: (string | boolean),
    readOnlyValue?: (string | boolean),
    requiredValue?: (string | boolean),
    visibleValue?: (string | boolean)) {
      this.disabled = disabled;
      this.readOnly = readOnly;
      this.required = required;
      this.visible = visible;
      this.disabledValue = disabledValue;
      this.readOnlyValue = readOnlyValue;
      this.requiredValue = requiredValue;
      this.visibleValue = visibleValue;
  }


}