import { LayoutCss } from "./css-interface";
import { OrientationEnum } from "./orientationEnum";
import { SectionColumn } from "./sectionColumn";

export class SectionLayout {
    column: SectionColumn;
    orientation: OrientationEnum;
    css: LayoutCss;

    constructor(options: {
        column: any,
        orientation: string,
        css?: any
    }) {
        if(options.column === undefined || options.column === null) {
            console.error("column of section cannot be null");
        } else {
            this.column = new SectionColumn(options.column);
        }
        this.orientation = this.getOrientation(options.orientation);
        this.css = options.css;
    }

    private getOrientation(orientation: string):OrientationEnum {
        if(orientation === OrientationEnum.horizontal) {
            return OrientationEnum.horizontal;
        } else if(orientation === OrientationEnum.vertical) {
            return OrientationEnum.vertical;
        }
        return OrientationEnum.horizontal;
    }
}