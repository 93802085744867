import { Component, OnInit, HostListener, Inject } from '@angular/core';
import { CoreService } from '@renderer/services/core.service';
import { Router, Event, RouterEvent, NavigationEnd, Route } from '@angular/router';
import { BodyComponent } from '@renderer/body/body.component';
import { AuthGuardService } from '@shared/services/auth-guard.service';
import { Store } from '@ngrx/store';
import * as fromRoot from '@shared/store';
import { forkJoin, of, Subscription } from 'rxjs';
import { delay, filter } from 'rxjs/operators';
import { Title, Meta } from '@angular/platform-browser';
import { CommonService } from '@shared/services/common.service';
import { environment } from '../environments/environment';
import * as _ from "lodash";
import { LinkTag, MetaTag } from '@lib-model/app-interfaces';
import { AppConfigurationProperties } from '@hmi/ui-component';
import { CssType, GenerateCSSService } from '@shared/services/generate-css.service';
import { DOCUMENT } from '@angular/common';
import { StyleTagId } from '@lib-model/constants';
import { UrlConfiguration } from '@lib-model/url-configuration';
import { AuthenticationService } from '@shared/services/authentication.service';
import { AppMessageService } from '@shared/services/app-message.service';
import { VALID_EVENTS } from '@lib-model/events';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  isRouteConfigLoaded = false;
  showFullPageLoader = false;
  loaderSubscription$: Subscription;
  globalPageSettings: Array<any> = [];
  currentPageSetting: any;
  hmiAppConfiguration: AppConfigurationProperties;

  deferredPrompt: any;
  showAddToHomeScreen = false;

  @HostListener('window:message',['$event'])
  onMessage(e) {
    if (e.data.action == "preview") {
      this.setPreviewAPIConfiguration(e.data?.data);
    }
  }

  @HostListener('window:beforeinstallprompt', ['$event'])
  onbeforeinstallprompt(e) {
    console.log(e);
    // Prevent Chrome 67 and earlier from automatically showing the prompt
    e.preventDefault();
    // Stash the event so it can be triggered later.
    this.deferredPrompt = e;
    if (environment.production) {
      this.showAddToHomeScreen = true;
    }
  }

  constructor(private coreService: CoreService, 
    private router: Router,
    private store: Store<fromRoot.State>, 
    private titleService: Title,
    private commonService: CommonService, 
    private metaService: Meta,
    private generateCSSService: GenerateCSSService,
    private authenticationService: AuthenticationService,
    private messenger: AppMessageService,
    @Inject(DOCUMENT) private documentService: Document){
      router.events.pipe(
        filter((event: Event): event is RouterEvent => event instanceof RouterEvent)
     ).subscribe((event: RouterEvent) => {
       this.messenger.removeOnRouteChange();
       if (event instanceof NavigationEnd) {
        this.currentPageSetting = this.globalPageSettings && this.globalPageSettings[event.url.slice(1)];
        this.refreshStyleTag();
        if(this.hmiAppConfiguration && this.hmiAppConfiguration.layoutProperties) {
          this.generateCSSService.readCSSConfig(this.hmiAppConfiguration.layoutProperties.css && this.hmiAppConfiguration.layoutProperties.css.configuration, CssType.TYPE_BODY, "body");
          this.generateCSSService.applyCSSConfig(StyleTagId.body);
        }

        if (this.currentPageSetting) {
          if(this.currentPageSetting.title || this.hmiAppConfiguration.title) {
            this.titleService.setTitle(this.currentPageSetting.title || this.hmiAppConfiguration.title);
          }

          if(this.currentPageSetting.layoutProperties && this.currentPageSetting.layoutProperties.css) {
            this.generateCSSService.readCSSConfig(this.currentPageSetting.layoutProperties.css && this.currentPageSetting.layoutProperties.css.configuration, CssType.TYPE_BODY, "body.page-styles");
            this.generateCSSService.applyCSSConfig(StyleTagId.body);
          }
          
        }
        this.generateCSSService.applyCSSConfig(StyleTagId.body);
       }
     });
    }

  setApiData(apiData) {
    if (apiData) {
      this.hmiAppConfiguration = new AppConfigurationProperties(apiData.hmiAppConfiguration || {});
      this.generateCSSService.readCSSConfig(this.hmiAppConfiguration.layoutProperties.css && this.hmiAppConfiguration.layoutProperties.css.configuration, CssType.TYPE_BODY, "body");
      this.generateCSSService.applyCSSConfig(StyleTagId.body);
      this.insertMetaTags();
      this.insertLinkTags();
      delete apiData['hmiAppConfiguration'];
      this.initRouteConfiguration(apiData);
      this.initSessionConfigurationAndRouting();
    }
  }

  ngOnInit() {
    this.coreService.fetchAPIConfiguration().subscribe({
    next: apiData => {
      this.setApiData(apiData);
      //delay below avoids the error : "Expression has changed after it was checked"
      //However it still occurs in some situations.
      this.loaderSubscription$ = this.store.select(fromRoot.selectLoader).pipe(delay(0))
        .subscribe( loader => { 
          this.showFullPageLoader = loader.fullPageCount > 0;
      });  
    }, 
    complete: () => {
      console.log('Cannot read JSON file name: app.conf.json');
    }});
    this.coreService.fetchFieldPropertyConf().subscribe((data:any)=>{
      data.forEach(element => {
        element?.customEvent?.forEach(item => {
          VALID_EVENTS.push(item.value);
        });
      });
    });
  }

  setPreviewAPIConfiguration(data) {
    this.coreService.setPreviewData(data?.pageJson || {});
    let previewAppConf = {
      preview: {
        data: {
        },
        field: {
        },
        footerField: {
        },
        footerSection: {
        },
        globalPageSettings: {
        },
        headerField: {
        },
        headerSection: {
        },
        section: {
        }
      },
      hmiAppConfiguration: null
    }
    if (data?.pageSettingConf?.globalPageSettings) {
      previewAppConf.preview.globalPageSettings = data.pageSettingConf.globalPageSettings;
    }
    if (data?.hmiAppConf) {
      previewAppConf.hmiAppConfiguration = data.hmiAppConf;
    }
    this.setApiData(previewAppConf);
    this.insertThemeCSSForPreview(data?.themeCSS);
    this.router.navigate(["preview"]);
  }

  insertThemeCSSForPreview(css) {
    let styleTag: HTMLStyleElement = this.documentService.head.querySelector("#theme-css-tag");
    if (styleTag) {
      styleTag.innerHTML = css;
    } else {
      styleTag = this.documentService.createElement('style');
      styleTag.id = "theme-css-tag";
      styleTag.type = 'text/css';
      styleTag.innerHTML = css;
      const appCSSTag = this.documentService.head.querySelector("link[href='styles.css']");
      appCSSTag.parentNode.insertBefore(styleTag, appCSSTag.nextSibling);
    }
  }

  initRouteConfiguration(apiData) {
    const config: any = this.router.config;
    Object.entries(apiData).map( (keyValueArray: any) => {
      const newRoute = keyValueArray[0].split(".").join("/"),
            newRouteConfig: Route = {
              path: newRoute,
              component: BodyComponent
            }

      if (keyValueArray[1].globalPageSettings && Object.keys(keyValueArray[1].globalPageSettings).length) {
        this.globalPageSettings[newRoute] = keyValueArray[1].globalPageSettings;
      }

      if (keyValueArray[1].authGuardConfig && keyValueArray[1].authGuardConfig.enabled) {
        newRouteConfig['canActivate'] = [AuthGuardService];
        newRouteConfig['authGuardConfig'] = keyValueArray[1].authGuardConfig;
      }
      config.unshift(newRouteConfig);
    });
    this.router.resetConfig(config);
  }

  navigateToRoute(currentConfig) {
    this.isRouteConfigLoaded = true;
    if (currentConfig && currentConfig.path) {
      const structuredNavigatablePath = this.commonService.getRoutableObject(this.router.config, this.router.url);
      this.router.navigate(structuredNavigatablePath.url, { queryParams: structuredNavigatablePath.queryParams });
    } else {
      this.router.navigate([this.hmiAppConfiguration.ifPageNotFound || ""]);
    }
  }

  initSessionConfigurationAndRouting() {
    const currentConfig = this.commonService.findRouteConfByUrl(this.router.config, this.router.url),
    apiConfigurations = this.hmiAppConfiguration.apiConfigurations;
    let promiseList = [];
    
    this.isRouteConfigLoaded = true;
    if (this.hmiAppConfiguration.interceptorConfigurations && this.hmiAppConfiguration.interceptorConfigurations.length) {
      this.authenticationService.setInterceptorConfigurations(this.hmiAppConfiguration.interceptorConfigurations);
    } 
    if (apiConfigurations && apiConfigurations.length) {
      apiConfigurations.forEach((apiConfiguration: UrlConfiguration) => {
        let failurePagePath: any = apiConfiguration && apiConfiguration.onError && apiConfiguration.onError.pageUrl,
          currentPath = currentConfig && currentConfig.path;
          if (failurePagePath) {
            failurePagePath = this.commonService.findRouteConfByUrl(this.router.config, apiConfiguration.onError.pageUrl)
            failurePagePath = failurePagePath && failurePagePath.path;
          }

          if (apiConfiguration && apiConfiguration.onError && failurePagePath !== currentPath && apiConfiguration.url) {
            promiseList.push(this.coreService.callApi(apiConfiguration, null));
          }
      });
    } 
    
    if (!promiseList.length) {
      promiseList.push(of({}));
    }

    forkJoin(promiseList).subscribe(() => {
      this.navigateToRoute(currentConfig);
    });
  }

  insertMetaTags() {
    if (this.hmiAppConfiguration.metaTags.length) {
        this.hmiAppConfiguration.metaTags.forEach((metaTagConfig: MetaTag) => {
          const metaObj = {};
          Object.keys(metaTagConfig).forEach((key: string) => {
            if (metaTagConfig[key]) {
              metaObj[key] = metaTagConfig[key];
            }
          });
          let metaSelector = "";
          if (metaTagConfig.charset) {
            metaSelector = "charset='utf-8'";
          } else if (['viewport', 'msapplication-TileColor', 'msapplication-TileImage', 'theme-color', 'apple-mobile-web-app-capable'].indexOf(metaTagConfig.name) !== -1) {
            metaSelector = "name='" + metaTagConfig.name + "'";
          }
          this.metaService.updateTag(metaObj, metaSelector);
        });
      }
  }

  insertLinkTags() {
    if (this.hmiAppConfiguration.linkTags.length) {
      this.hmiAppConfiguration.linkTags.forEach((linkTagObj: LinkTag) => {
          const link = this.documentService.createElement('link');
            Object.keys(linkTagObj).forEach((key: string) => {
              if (linkTagObj[key]) {
                link[key] = linkTagObj[key];
              }
            });
            if (link.rel) {
              link.rel = "stylesheet";
            }
            this.documentService.head.appendChild(link);
        });
      }
  }

  refreshStyleTag() {
    let styleTag = this.documentService.getElementById(StyleTagId.page);
    if (styleTag) {
      styleTag.innerHTML = "";
    }

    styleTag = this.documentService.getElementById(StyleTagId.body);
    if (styleTag) {
      styleTag.innerHTML = "";
    }
  }
  
  addToHomeScreen() {
    // hide our user interface that shows our A2HS button
    this.closeAddToHomeScreen();
    // Show the prompt
    this.deferredPrompt.prompt();
    // Wait for the user to respond to the prompt
    this.deferredPrompt.userChoice
      .then((choiceResult) => {
        if (choiceResult.outcome === 'accepted') {
          console.log('User accepted the A2HS prompt');
        } else {
          console.log('User dismissed the A2HS prompt');
          this.closeAddToHomeScreen();
        }
        this.deferredPrompt = null;
      });
  }

  closeAddToHomeScreen() {
    this.showAddToHomeScreen = false;
  }
}
