import { DOCUMENT } from '@angular/common';
import { Component, ComponentFactoryResolver, EventEmitter, Inject, Input, OnDestroy, OnInit, Output, Renderer2, ViewChild, ViewContainerRef } from '@angular/core';
import { ElementHostDirective } from '@common-directives/element-host.directive';
import { CharField } from '../../form/interfaces/char-field';
import { UntypedFormGroup } from '@angular/forms';
import { FormsService } from '@lib-service/forms.service';
import { FieldDynamicAttributes } from '../../form/interfaces/field-dynamic-attributes';
import { Observable } from 'rxjs';
import { UrlConfiguration } from '@lib-model/url-configuration';
import * as ExternalComponents from 'external-components';
import * as _ from "lodash";
import { ViewComponent } from '@elements/view/view.component';
import { HMIEvent, VALID_EVENTS } from '@lib-model/events';
import { EventAction } from '@lib-service/event.action';

// @Component({
//   selector: 'hmi-dynamic-creator',
//   templateUrl: './dynamic-creator.component.html',
//   styleUrls: ['./dynamic-creator.component.css']
// })
@Component({
  selector: 'hmi-dynamic-creator',
  template: `
  <span ngClass="{{ applyLabelPlacementClass() }}" [formGroup]="formGroupObj">
    <label for="{{fieldObj.baseProperties.name}}" *ngIf="fieldObj.baseProperties.label" 
      class="field-label">{{fieldObj.baseProperties.label}}</label>
    <ng-template hmiElementHost></ng-template>    
  </span> 
  `,
  styleUrls: ['./dynamic-creator.component.css']
})
export class DynamicCreatorComponent extends ViewComponent implements OnInit, OnDestroy {
  
  @Input() fieldObj: CharField | any; //TODO: change to dynamic field class
  @Input() dynamicAttributes: FieldDynamicAttributes;
  //TODO: Revisit how the customApiCall function is passed called throughout the app. 
  //Probably we should implement this in a new library service and import in both projects
  @Input() customApiCall: (urlConfig: UrlConfiguration, CUSTOM_FIELD_OBJECT?: any) => Observable<any>;

  @ViewChild(ElementHostDirective, {static: true}) elementHost: ElementHostDirective; 
  
  directiveName = 'hmiElementHost';
  formGroupObj: UntypedFormGroup;
  componentRef: any;
  componentValueSetKey: string = 'value';

  constructor(private componentFactoryResolver: ComponentFactoryResolver, 
    @Inject(DOCUMENT) private document: Document, private formService: FormsService,
    private renderer: Renderer2, eventAction: EventAction
  ) { 
    super(eventAction);
  }  

  loadComponent() {
    var componentAttributes = _.cloneDeep(this.fieldObj.customAttributes) || {};
    const componentClass = ExternalComponents[this.fieldObj.libraryComponent];
    if (componentAttributes.componentValueSetKey) {  //TODO: Can be moved to ngOnInit after class implementation
      this.componentValueSetKey = componentAttributes.componentValueSetKey;
    }
    const viewContainerRef: ViewContainerRef = this.elementHost.viewContainerRef;
    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(componentClass);
    viewContainerRef.clear();    

    let componentRef;
    if (componentAttributes.content) {
      const element = this.document.createTextNode(componentAttributes.content);
      const content =  [[element]];
      componentRef = viewContainerRef.createComponent<typeof componentClass>(componentFactory, 0, null, content);
    } else {
      componentRef = viewContainerRef.createComponent<typeof componentClass>(componentFactory, null, null, null);
    }

    componentRef.instance.fieldObj = this.fieldObj; //TODO: Clonedeep immutable objects
    componentRef.instance.dynamicAttributes = this.dynamicAttributes;
    componentRef.instance.formGroupObj = this.formGroupObj;
    componentRef.instance.customApiCall = this.customApiCall;    
    
    componentRef.instance.name = componentAttributes.name;
    if (componentAttributes.label !== '' && componentAttributes.label != null) {
      componentRef.instance.label = componentAttributes.label;
    }    
    
    for (var key in componentAttributes) {
      if (this.fieldObj.customAttributes.hasOwnProperty(key)) {
        componentRef.instance[key] = this.fieldObj.customAttributes[key];
      }
    }
    
    //componentRef.instance.indeterminate = true;   //property of MatCheckbox

    //Styles
    const compElement = componentRef.location.nativeElement;
    compElement.style.width = '100%'; //TODO: Some widget configs maybe required so it actually takes 100% width

    //Loading value in component
    if (this.fieldObj.value != null && this.fieldObj.value !== "") {
      componentRef.instance[this.componentValueSetKey] = this.fieldObj.value;
    }    
    
    //DataMapping    
    if (componentAttributes.dataMapping && componentAttributes.dataMapping.type) {
      if (componentAttributes.dataMapping.type === 'static') {
        componentRef.instance[componentAttributes.dataMapping.mappedField] = componentAttributes.dataMapping.dataValues;

        //Loading value in component
        /** Sample setting:
         * dataMapping: {
            type: 'static',
            dataObjectType: 'Array',  //not useful right now, could be deleted
            selectValueKey: 'value',
            mappedField: 'options',        
            dataValues: [
              {"name": "pune", "value": "pune", "label": "Pune"},
              {"name": "mumbai", "value": "mumbai", "label": "Mumbai"}
            ]
          }
         */
        if (this.fieldObj.value != null && this.fieldObj.value !== "") {
          if (componentAttributes.dataMapping.selectValueKey) {
            componentAttributes.dataMapping.dataValues.forEach(dataObj => {
              if (dataObj[componentAttributes.dataMapping.selectValueKey] === this.fieldObj.value) {
                componentRef.instance[this.componentValueSetKey] = dataObj;
                componentRef.instance.value = dataObj.value;
                componentRef.changeDetectorRef.detectChanges();
              }
            });
          } else {
            componentRef.instance[this.componentValueSetKey] = this.fieldObj.value;
          }
        }
        
      }
    }

    this.formGroupObj.controls[this.fieldObj.baseProperties.name].valueChanges.subscribe(val => {
      if(this.fieldObj.value !== val) { //Value change should be triggered only if actual value has changed.
        let dynamicAttributes = _.cloneDeep(this.dynamicAttributes);
        dynamicAttributes.value = val;
        this._dataChange.emit({ dynamicData: dynamicAttributes });
      }
    });

    componentRef.instance.initializeEvents.subscribe((data) => {
      if (data && data.name === "fireEvent") {
        data.events.forEach(event => {
          this.handleEvents(event, this.fieldObj.baseProperties.formName, this.fieldObj);
        });
      } else {
        this.initializeEvents();
      }
    });

    this.componentRef = componentRef;
  }  

  ngOnInit(): void {
    
    this.formGroupObj = this.formService.createInputControl(this.fieldObj, this.dynamicAttributes);    
    this.loadComponent();
  }  

  applyLabelPlacementClass() {
    let className = 'input-label__placement-top';
    if (this.fieldObj.layoutProperties.labelPlacement
       && this.fieldObj.layoutProperties.labelPlacement.toLowerCase() === 'left') {
      className = 'input-label__placement-left';
    }
    return className;
  }

  callAPIAction(urlConfig: UrlConfiguration, customData?: any): Observable<any> {
    return this.customApiCall(urlConfig, customData);
  }

  loadNewDataAction(dataMappingConfigs: Array<{inputName: string, dataAccessor: string}>, data: any): void {
    dataMappingConfigs.forEach(config => {
      this.componentRef.instance[config.inputName] = data[config.dataAccessor];
    });
  }

  initializeEvents() {
    if (this.fieldObj.events && this.fieldObj.events.length) {
      _.forEach(this.fieldObj.events, (event: HMIEvent) => {
        const primeElement = this.componentRef.instance && this.componentRef.instance.primeElement && this.componentRef.instance.primeElement;
        if (primeElement) {
          if (event.event && event.actions && event.actions.length && VALID_EVENTS.indexOf(event.event) !== -1 
            && (primeElement.nativeElement || (primeElement.input && primeElement.input.nativeElement))) {
            this.renderer.listen(primeElement.nativeElement || (primeElement.input && primeElement.input.nativeElement), event.event, _.debounce(() => {
              this.handleEvents(event, this.fieldObj.baseProperties.formName, this.fieldObj);
            }, event.debounce || 0));
        } else {
          primeElement[event.event].subscribe(() => {
            this.handleEvents(event, this.fieldObj.baseProperties.formName, this.fieldObj);
          });
        }
        }
      });
    }
  }

  ngOnDestroy():void {
    if (this.componentRef) {
      this.componentRef.destroy();
    }
  }

}
