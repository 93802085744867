import { Field } from './field';
import { DropdownOption } from './dropdown-field';

export class RadioField extends Field<DropdownOption> {
    optionList: Array<DropdownOption>;
    displayKey: string;
    outputKey: string;

    constructor(options :{
      value?: any;
      type : string;
      optionList: Array<DropdownOption>;
      displayKey: string;
      outputKey: string;
      orientation: string;
      }) {
      super(options);
      this.optionList = options.optionList;
      this.displayKey = options.displayKey;
      this.outputKey = options.outputKey;
      this.setValue(options.value);
    }

    getValue(): string {
        if (this.value === undefined) {
            return null;
        }
        console.log("Radio value: " + (this.value && this.value.name));
        return this._value.value;
    }

    setValue(value: string) {
        if (this.optionList && this.optionList.length) {
            const that = this;
            this.optionList.some(function(option) {
                if (option.value === value) {
                    that._value = option;
                    return true;
                }
            });
            console.log("Value of radio field is: "+ (this._value && this._value.value));
        } else {
            console.error("Dropdown field:" + this.baseProperties.name + " value not found in available options.");
            this._value = null;
        }
    }

    get value() {
        return this._value;
    }

    set value(value: any) {
        this.setValue(value);
    }
}