import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AppMessageService {

  //TODO: Convert below array to observable
  messages: any[] = [];

  messageType = {
    error: 'danger',
    success: 'success'
  }

  showMessage (header: string, body: string, type = "error", keepOnRouteChange: any= false) {
    type = this.messageType[type] ? this.messageType[type] : 'info';
    this.messages.push({ header, body, type, keepOnRouteChange});
  }

  removeMessage (message) {
    this.messages = this.messages.filter( msg => msg.body != message.body);
  }
  removeOnRouteChange () {
    this.messages = this.messages.filter( msg => msg.keepOnRouteChange == true);
  }
}
