import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { CoreService } from '@renderer/services/core.service';
import { Observable, of, forkJoin } from 'rxjs';
import { mergeMap, catchError, map } from 'rxjs/operators';
import { Action } from '@ngrx/store';

import * as PageActions from './page.action';
import { JsonType } from 'libs/ui-component/src/lib/models/constants';

@Injectable()
export class PageEffects {

    constructor(
        private actions$: Actions,
        private coreService: CoreService 
    ){}    

    //TODO: Pass payload: BODY, HEADER, NAVBAR and modify code to fetch their config and data 
    LoadPageConfigAndData$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(PageActions.LoadPageConfigAndData),
      mergeMap(action => 
        forkJoin(this.coreService.fetchStructuralConfiguration(JsonType.SECTION),
        this.coreService.fetchStructuralConfiguration(JsonType.FIELD),
        this.coreService.fetchStructuralConfiguration(JsonType.DATA)).pipe(
          map((data: any) => {
            return PageActions.LoadPageConfigAndDataSuccess({ data, segment: action.segment, sharedData: action.sharedData });
          }),
          catchError((error: Error) => {
            return of(PageActions.LoadPageConfigAndDataFail({ error }));
          })
        )
      )
    ));

    LoadHeaderConfigAndData$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(PageActions.LoadHeaderConfigAndData),
      mergeMap(action => 
        forkJoin(this.coreService.fetchStructuralConfiguration(JsonType.HEADERSECTION),
        this.coreService.fetchStructuralConfiguration(JsonType.HEADERFIELD),
        this.coreService.fetchStructuralConfiguration(JsonType.HEADERDATA)).pipe(
          map((data: any) => {
            return PageActions.LoadHeaderConfigAndDataSuccess({ data, segment: action.segment, sharedData: action.sharedData });
          }),
          catchError((error: Error) => {
            return of(PageActions.LoadHeaderConfigAndDataFail({ error }));
          })
        )
      )
    ));

    LoadNavConfigAndData$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(PageActions.LoadNavConfigAndData),
      mergeMap(action => 
        forkJoin(this.coreService.fetchStructuralConfiguration(JsonType.NAVSECTION),
        this.coreService.fetchStructuralConfiguration(JsonType.NAVFIELD),
        this.coreService.fetchStructuralConfiguration(JsonType.NAVDATA)).pipe(
          map((data: any) => {
            return PageActions.LoadNavConfigAndDataSuccess({ data, segment: action.segment, sharedData: action.sharedData });
          }),
          catchError((error: Error) => {
            return of(PageActions.LoadNavConfigAndDataFail({ error }));
          })
        )
      )
    ));

    LoadFooterConfigAndData$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(PageActions.LoadFooterConfigAndData),
      mergeMap(action => 
        forkJoin(this.coreService.fetchStructuralConfiguration(JsonType.FOOTERSECTION),
        this.coreService.fetchStructuralConfiguration(JsonType.FOOTERFIELD),
        this.coreService.fetchStructuralConfiguration(JsonType.FOOTERDATA)).pipe(
          map((data: any) => {
            return PageActions.LoadFooterConfigAndDataSuccess({ data, segment: action.segment, sharedData: action.sharedData });
          }),
          catchError((error: Error) => {
            return of(PageActions.LoadFooterConfigAndDataFail({ error }));
          })
        )
      )
    ));
}