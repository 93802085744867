import { Inject, Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpResponse } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, forkJoin, throwError, of } from 'rxjs';
import {  catchError, finalize, map } from 'rxjs/operators';
import { CommonService } from '@shared/services/common.service';
import * as _ from "lodash";
import { UrlConfiguration, FieldDynamicAttributes, Field, Button, ValueFormatConstant, MappedFieldConstant, JsonType } from '@hmi/ui-component';
import { UtilsService } from '@shared/services/utils.service';
import * as moment from 'moment';
import * as fromRoot from '@shared/store';
import * as pageActions from '@shared/store/page/page.action';
import { AppMessageService } from '@shared/services/app-message.service';
import { AppDataService } from '@shared/services/app-data.service';
import { Store } from '@ngrx/store';
import { EvaluateExprComponent } from '@hmi/extensions';
import { Dictionary } from '@hmi/modellib';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { iterateArrayMap } from '@shared/store/page/page.reducer';
import { MODALS } from '@elements/modal/modal.component';
import { AuthenticationService } from '@shared/services/authentication.service';
import { ACTION_TYPE, HMIAction } from '@lib-model/events';
import { DOCUMENT } from '@angular/common';
import * as ExternalComponents from 'external-components';
import { SUPPORTED_CONTENT_TYPE } from '@lib-model/url-configuration';
@Injectable({
  providedIn: 'root'
})
export class CoreService {
  appBaseUrl:string;
  isPreview = false;
  previewJsonData: any;
  apiConf = {
    data: {},
    url: './assets/configuration/app.conf.json'
  };
  fieldPropertyConf = {
    data: {},
    url: './assets/propertyMap/field-property-configuration.json'
  };
  private renderer: Renderer2;
  withCredentials: boolean;
  
  constructor(private commonService: CommonService,
    private router: Router,
    private route: ActivatedRoute,
    private httpClient: HttpClient,
    private utilsService: UtilsService,
    private messenger: AppMessageService,
    private appData: AppDataService,
    private store: Store<fromRoot.State>,
    private modalService: NgbModal,
    private rendererFactory2: RendererFactory2,
    @Inject(DOCUMENT) private document: Document,
    private authenticationService: AuthenticationService) {
      this.renderer = this.rendererFactory2.createRenderer(null, null);
    }

  setPreviewData(jsonData) {
    this.isPreview = true;
    this.previewJsonData = jsonData;
  }

  fetchAPIConfiguration(): Observable<any> {
    return this.httpClient.get(this.apiConf.url).pipe(map((data) => {
      this.apiConf.data = data;
      this.appBaseUrl = (data['hmiAppConfiguration'] && data['hmiAppConfiguration'].baseUrl) || '';
      return data;
    }));
  }

  fetchFieldPropertyConf(){
    return this.httpClient.get(this.fieldPropertyConf.url);

    }

  fetchStructuralConfiguration(jsonType): Observable<any> {
    if (this.isPreview) {
      return of(this.previewJsonData[jsonType] || {});
    }

    const pageData = this.commonService.findApiConfByUrl(this.apiConf.data, this.router.url);
    let dataUrl: (string | UrlConfiguration[]);
    if (pageData && pageData[jsonType]) {
      if (pageData[jsonType].fetchFromLocal) {
        let localUrl = pageData[jsonType].localURL;
        if (_.isObject(localUrl)) {
          if (_.isEmpty(localUrl)) {
            dataUrl = "";
          } else if (this.commonService.isMobileDevice() && localUrl.mobileURL) {
            dataUrl =  localUrl.mobileURL;
          } else if (localUrl.desktopURL) {
            dataUrl =  localUrl.desktopURL;
          } else {
            dataUrl = "";
          }
        } else {
          dataUrl =  localUrl;
        }
      } else {
        dataUrl = <UrlConfiguration[]>pageData[jsonType].serverURL;
      }
      if (jsonType === JsonType.DATA || jsonType === JsonType.HEADERDATA || jsonType === JsonType.NAVDATA) {
        if (typeof dataUrl !== 'string') {
          const urlArray = [];
          dataUrl.forEach( urlConfiguration => {
            //TODO: Implement GET, POST, PUT methods using "method" property of urlConfiguration
            //Default is GET for now.
            const modifiedUrl = this.prepareUrlBeforeHttpRequest(urlConfiguration);
            urlArray.push(this.httpClient.get(modifiedUrl).pipe(catchError((err) => {
              this.messenger.showMessage(null, err);
              return err;
            })));
          });
          return forkJoin(urlArray).pipe(map((dataList) => {
            const dataMap = {};
            dataList.forEach( (dataObject, index) => {
              const apiObj = <UrlConfiguration>dataUrl[index];
              dataMap[apiObj.name] = dataObject;
              if (apiObj.onSuccess && apiObj.onSuccess.sharedData && apiObj.onSuccess.sharedData.length) {
                this.appData.setSharedDataByKey(apiObj.onSuccess.sharedData, dataObject)
              }
            });
            return dataMap;
          }));
        } else if (dataUrl === "") {
          return of({});
        } else {
          return this.httpClient.get(dataUrl);
        }
      }
      return this.httpClient.get(dataUrl.toString());
    } else if (jsonType === JsonType.DATA || jsonType === JsonType.HEADERDATA || jsonType === JsonType.NAVDATA || jsonType === JsonType.FOOTERDATA) {
      return of({});
    }
    return throwError("URL configuration unavailable for: "+jsonType);
  }

  getFileNameFromHttpResponse(httpResponse: HttpResponse<any>) {
    var contentDispositionHeader = httpResponse.headers.get('Content-Disposition');
    var result = contentDispositionHeader.split(';')[1].trim().split('=')[1];
    return result.replace(/"/g, '');
  }

  downloadFile(response: HttpResponse<any>, urlConfig: UrlConfiguration) {
    let fileName = this.getFileNameFromHttpResponse(response),
        binaryData = [];
    binaryData.push(response.body);
    let downloadLink = document.createElement('a');
    downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, {type: urlConfig.responseType}));
    downloadLink.setAttribute('download', fileName || urlConfig.downloadFileName || "download.txt");
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  }

  getHttpOptions(urlConfig: UrlConfiguration) {
    /*if(urlConfig.headerData){
      urlConfig.headerData.forEach((headerValue)=>{
        console.log(headerValue);
        
      })
    }*/
    if(urlConfig.isWithCredentials === true || urlConfig.isWithCredentials === '' || urlConfig.isWithCredentials === undefined || urlConfig.isWithCredentials === null){
      this.withCredentials = true; // withCredentials needs to be true by default
    }else{
      this.withCredentials = false;
    }

    let options:any = {
      withCredentials: this.withCredentials,
      observe: 'response'
    };
    const httpHeaders = new HttpHeaders();
    if (urlConfig.fileUpload) {
      options.headers = httpHeaders.set('File-Upload', 'true');
    }
    if (urlConfig.contentType && SUPPORTED_CONTENT_TYPE.indexOf(urlConfig.contentType?.toLowerCase()) !== -1) {
      options.headers = httpHeaders.set("Content-Type", urlConfig.contentType.toLowerCase());
    }
    options.responseType = urlConfig.responseType ? urlConfig.responseType : 'json';
    return options;
  }

  setPayloadByContentType(urlConfig: UrlConfiguration, payload: any) {
    let finalPayload = payload;
    if (Object.keys(payload)?.length) {
      switch (urlConfig.contentType?.toLowerCase()) {
        case "text/plain":
          finalPayload = Object.entries(payload)?.[0]?.[1];; 
      }
    }
    return finalPayload;
  }

  callApi(urlConfig: UrlConfiguration, fields: Map<string, Array<Field<any>>>, fieldsDynamicAttr?: Map<string, Array<FieldDynamicAttributes>>, CUSTOM_FIELD_OBJECT?) {
    const rawUrl = this.prepareUrlBeforeHttpRequest(urlConfig, fieldsDynamicAttr, CUSTOM_FIELD_OBJECT);
    let httpPromise, 
        finalUrl = this.isValidHttpURL(urlConfig.url) ? rawUrl : ((this.isValidHttpURL(this.appBaseUrl) ? this.appBaseUrl : '') + rawUrl),
        payload = this.fetchParamsValueMap(urlConfig.payloadParams || [], fieldsDynamicAttr, CUSTOM_FIELD_OBJECT);
    
    payload = this.setPayloadByContentType(urlConfig, payload);

    switch(urlConfig.method.toUpperCase()) {
      case 'GET':
        httpPromise = this.httpClient.get(finalUrl, this.getHttpOptions(urlConfig));
        break;
      case 'DELETE':
        /*if (urlConfig.fileUpload) {

        }*/
        httpPromise = this.httpClient.delete(finalUrl, this.getHttpOptions(urlConfig));
        break;
      case 'POST':
        if (urlConfig.fileUpload) {
          let formData: FormData = new FormData();
          for (let plKey in payload) {
            formData.append(plKey, payload[plKey]);
          }
          payload = formData;
        }
        httpPromise = this.httpClient.post(finalUrl, payload, this.getHttpOptions(urlConfig));
        break;
      case 'PUT':
        httpPromise = this.httpClient.put(finalUrl, payload, this.getHttpOptions(urlConfig));
        break;
      case 'PATCH':
        httpPromise = this.httpClient.patch(finalUrl, payload, this.getHttpOptions(urlConfig));
        break;
    }

    if (httpPromise) {
      return httpPromise.pipe(
        map( (response: HttpResponse<any>) => {
          if (urlConfig.responseType === "blob" || urlConfig.responseType === "arraybuffer") {
            this.downloadFile(response, urlConfig);
            return of({});
          } else {
            let result = response.body;
            if (urlConfig.isApplicationSessionAPI) {
              this.authenticationService.setAutheticationSuccessful();
            }
            this.appData.onFormSubmitSuccess(result, urlConfig, fields);
            return urlConfig.onSuccess && urlConfig.onSuccess.apiDataAccessor ? result[urlConfig.onSuccess.apiDataAccessor] : result;
          }
        }),
        map((response: HttpResponse<any>) => {
          return urlConfig.postTransform ? ExternalComponents[urlConfig.postTransform](response) : response;
        }),
        catchError((error: HttpErrorResponse) => {
          if (urlConfig.isApplicationSessionAPI) {
            this.authenticationService.setAutheticationFailure();
          }
          this.appData.onFormSubmitFailure(error, urlConfig, fields);
          return throwError(error);
        })
      );
    } else {
      console.error('No HTTP method type is matched');
    }
  }

  private isValidHttpURL(string) {
    let url;
    try {
      url = new URL(string);
    } catch (_) {
      return false;  
    }
    return url.protocol === "http:" || url.protocol === "https:";
  }

  prepareUrlBeforeHttpRequest(urlConfig: UrlConfiguration, fieldsList?: Map<string, Array<FieldDynamicAttributes>>, CUSTOM_FIELD_OBJECT?): string {
    let modifiedUrl = urlConfig.url;
    let urlPathList = urlConfig.url.split('/');
    
    if (urlConfig.pathParams && urlConfig.pathParams.length) {
      const pathParams:any = this.fetchParamsValueMap(urlConfig.pathParams, fieldsList, CUSTOM_FIELD_OBJECT, urlConfig.encodePathAndQueryParams);
            
      urlPathList = urlPathList.map( path => {
        if (path.startsWith(':')) {
          const pathName = path.substr(1);
          return pathParams[pathName];
        }
        return path;
      });
      modifiedUrl = urlPathList.join('/');
      console.log("Calling URL: "+modifiedUrl);      
    }

    if (urlConfig.queryParams && urlConfig.queryParams.length) { 
      const queryParams:any = this.fetchParamsValueMap(urlConfig.queryParams, fieldsList, CUSTOM_FIELD_OBJECT, urlConfig.encodePathAndQueryParams);
      
      urlConfig.queryParams.forEach( (paramConfig, index) => {
        if (index === 0) {
          modifiedUrl = modifiedUrl + '?' + paramConfig.paramName + '=' + queryParams[paramConfig.paramName];
        } else {
          modifiedUrl = modifiedUrl + '&' + paramConfig.paramName + '=' + queryParams[paramConfig.paramName];
        }
      });
    }
    return modifiedUrl;
  }

  fetchParamsValueMap(urlParamsConfig: any[], fieldsList?: Map<string, Array<FieldDynamicAttributes>>, CUSTOM_FIELD_OBJECT?, encode?: boolean): any {
    const urlParamsValueMap = {};
    urlParamsConfig.forEach((paramConfig) => {
      let value;
      if (paramConfig.mappedField === MappedFieldConstant.CUSTOM_FIELD_OBJECT) {
        value = _.get(CUSTOM_FIELD_OBJECT, paramConfig.mappedValue);
      } else if (paramConfig.mappedField === MappedFieldConstant.$URL_PATH_PARAMS) {
        value = _.get(this.commonService.getRouteData().params, paramConfig.mappedValue);
      } else if (paramConfig.mappedField === MappedFieldConstant.$URL_QUERY_PARAMS) {
        value = _.get(this.commonService.getRouteData().queryParams, paramConfig.mappedValue);
      } else {  //Fetch field value from field list
        this.utilsService.iterateArrayMap(fieldsList, (fieldsDynamicAttr:FieldDynamicAttributes) => {
          if (fieldsDynamicAttr.name === paramConfig.mappedField) {
            if (!fieldsDynamicAttr.value && fieldsDynamicAttr.value !== 0 && fieldsDynamicAttr.value !== '' && fieldsDynamicAttr.value !== false) {
              value = "";
            } else if (paramConfig.mappedValue && fieldsDynamicAttr.value) {
              value = _.get(fieldsDynamicAttr.value, paramConfig.mappedValue);
            } else { //Other than date field, value shouldnt be an object here
              value = fieldsDynamicAttr.value;
            }                        
            //TODO: Beak from this iteration as field already found.              
          }
        });
      }
      if (paramConfig?.valueFormat?.includes(ValueFormatConstant.$SHARED_DATA)) {
        const shareDataAccessor = paramConfig.valueFormat.match(/\((.*?)\)/)[1];
        value = this.appData.getSharedDataByAccessor(shareDataAccessor);
      } else if (value && paramConfig.valueFormat) {
        if (paramConfig.valueFormat.includes(ValueFormatConstant.$DATETIME)) {
          //TODO: Needs to be tested
          const datetimeFormat = paramConfig.valueFormat.match(/\((.*?)\)/)[1];
          value = moment().year(value.year)
                .month(parseInt(value.month, 10) - 1).date(value.day)
                .hour(value.hour).minutes(value.minutes)
                .format(datetimeFormat);
        } else if (paramConfig.valueFormat.includes(ValueFormatConstant.$DATE)) {
          const dateFormat = paramConfig.valueFormat.match(/\((.*?)\)/)[1];
          const selectedDate =  moment().year(value.year)
                .month(parseInt(value.month, 10) - 1).date(value.day)
                value = selectedDate.format(dateFormat);
        } else if (paramConfig.valueFormat.includes(ValueFormatConstant.$TIME)) {
          const timeFormat = paramConfig.valueFormat.match(/\((.*?)\)/)[1];
          value = moment(value)
                .format(timeFormat);
        } else if (paramConfig.valueFormat === ValueFormatConstant.$NUMBER) {
          value = +value;
        } else if (paramConfig.valueFormat === ValueFormatConstant.$CHECKBOX) {
          const valArr = [];
          Object.entries(value).map((obj: Array<any>) => {
            if (obj[1]) {
              valArr.push(obj[1].value);
            }
          });
          value = valArr;
        } 
      } 

      const keyArr = (paramConfig.paramName && paramConfig.paramName.split(".")) || [],
            lastKey = keyArr[keyArr.length - 1];
      let objToIterate = urlParamsValueMap;
      keyArr.forEach( (key, index, arr) => {
        if (index < arr.length - 1) {
          if (!(key in objToIterate)) {
            objToIterate[key] = {};
          }
          objToIterate = objToIterate[key];
        }      
      });
      objToIterate[lastKey] = value;
    });
    if (encode) {
      return this.encodeParamValue(urlParamsValueMap);
    }
    return urlParamsValueMap;
  }

  private encodeParamValue(obj) {
    // Handle different data types
    if (typeof obj === 'object') {
      // If it's an array, iterate through its elements
      if (Array.isArray(obj)) {
        for (let i = 0; i < obj.length; i++) {
          obj[i] = this.encodeParamValue(obj[i]);
        }
      } else {
        // If it's an object, iterate through its properties
        for (const key in obj) {
          if (obj.hasOwnProperty(key)) {
            obj[key] = this.encodeParamValue(obj[key]);
          }
        }
      }
    } else if (typeof obj === 'string') {
      // If it's a string, encode it using JSON.stringify
      obj = window.encodeURIComponent(obj);
    }

    return obj;
  }

  private getSharedDataValueViaAccessor(action: HMIAction) {
    let value;
    const keyAccessor = action?.fieldValue?.split("$SHARED_DATA$."); 
    if (action?.fieldAccessor && Object.keys(action.fieldAccessor).length) {
      value = {};
      for (const mappedKey in action.fieldAccessor) {
        value[mappedKey] = this.appData.getSharedDataByAccessor(action.fieldAccessor[mappedKey]);
      }
    } else if (keyAccessor?.length && keyAccessor[1]) {
      value = this.appData.getSharedDataByAccessor(keyAccessor[1]);
    }
    return value;
  } 

  private downloadFileURI(action: HMIAction, fieldsList: Map<string, Array<FieldDynamicAttributes>>) {
    const link = this.renderer.createElement("a");
    if (action.fileName) {
      link.download = action.fileName;
    }
    let url = "";
    if (action.url) {
      url = action.url;
    } else if (action.field && action.fieldValue) {
      this.utilsService.iterateArrayMap(fieldsList, (fieldsDynamicAttr:FieldDynamicAttributes) => {
        if (fieldsDynamicAttr.name === action.field) {
          if (!fieldsDynamicAttr.value && fieldsDynamicAttr.value !== 0 && fieldsDynamicAttr.value !== '' && fieldsDynamicAttr.value !== false) {
            url = "";
          } else if (action.fieldValue && fieldsDynamicAttr.value) {
            url = _.get(fieldsDynamicAttr.value, action.fieldValue);
          } else { //Other than date field, value shouldnt be an object here
            url = fieldsDynamicAttr.value;
          }                        
          //TODO: Beak from this iteration as field already found.              
        }
      });
    } else if (action.fieldValue) {
      if (action.fieldValue.indexOf("$SHARED_DATA$") !== -1) {
        url = this.getSharedDataValueViaAccessor(action);
      } else {
        url = action.fieldValue;
      }
    }
    link.href = url;
    this.renderer.appendChild(this.document.body, link);
    link.click();
    this.renderer.removeChild(this.document.body, link);
  }

  public handleFieldUserInput(newFieldObj: any, fields?: Map<string, Array<Field<any>>>, fieldsDynamicAttr?: Map<string, Array<FieldDynamicAttributes>>) {
    const sharedData = this.appData.getAllSharedData();
    if (newFieldObj.eventName === "SET_SHARED_DATA") {
      this.appData.setSharedDataByKey(newFieldObj.sharedDataConfList);
    } if (newFieldObj.eventName === "DOWNLOAD_FILE_URI") {
        this.downloadFileURI(newFieldObj.action, fieldsDynamicAttr);
    } else if (newFieldObj.eventName === "SET_FIELD_WITH_SHARED_DATA"
              && newFieldObj.action.field 
              && newFieldObj.action.fieldValue 
              && (newFieldObj.action.fieldValue.indexOf("$SHARED_DATA$.") !== -1 
                || (newFieldObj.action.fieldValue.indexOf("$SHARED_DATA$") !== -1) 
                  && newFieldObj.action?.fieldAccessor && Object.keys(newFieldObj.action.fieldAccessor))) {
      const keyAccessor = newFieldObj.action.fieldValue.split("$SHARED_DATA$."); 
      if (keyAccessor.length) {
        let newVal = this.getSharedDataValueViaAccessor(newFieldObj.action);
        
        this.store.dispatch(pageActions.UpdateFieldValue({ id: newFieldObj.action.field.replaceAll('$', ""), value: newVal, isValueOverride: true }));
        this.store.dispatch(pageActions.CheckForUpdate({ id: newFieldObj.action.field.replaceAll('$', ""), sharedData: sharedData }));
      }
    }
    else if (newFieldObj.dynamicData) {
      this.store.dispatch(pageActions.UpdateFieldValue({ id: newFieldObj.dynamicData.id, value: newFieldObj.dynamicData.value }));
      this.store.dispatch(pageActions.CheckForUpdate({ field: newFieldObj.dynamicData, sharedData: sharedData }));
    } else if (newFieldObj.submitFormData) {
      const fieldObj: Button = newFieldObj.submitFormData.fieldObj;
      fieldObj.apiConfig = newFieldObj.submitFormData.action;
      const sharedDataList = fieldObj.apiConfig && fieldObj.apiConfig.onSuccess && fieldObj.apiConfig.onSuccess.sharedData;
      const sharedDataAttrList = sharedDataList && sharedDataList.length ? sharedDataList.map(sharedDataObj => sharedDataObj.varName) : [];
      if (fieldObj.apiConfig &&  fieldObj.apiConfig.beforeSubmit && fieldObj.apiConfig.beforeSubmit.action && fieldObj.apiConfig.beforeSubmit.action.length) {
        fieldObj.apiConfig.beforeSubmit.action.forEach(actionObj => {
          if (actionObj.type === ACTION_TYPE.CONFIRM_MODAL) {
            const evalExpr = new EvaluateExprComponent(),
                  fieldDictionary = this.getFieldsDictionary(fields);
            let isCondSatisfied = true;

            if (actionObj.condition) {
              isCondSatisfied = evalExpr.evaluateExpression(fieldDictionary, sharedData, actionObj.condition, newFieldObj.submitFormData.fieldsStatus);
            }
            if (isCondSatisfied) {
              const modalRef = this.modalService.open(MODALS.CONFIRM_MODAL);
              modalRef.componentInstance.data = actionObj;
              modalRef.result.then((result) => {
                console.log("Ok button is pressed");
                this.proceedToSubmit(newFieldObj, fields, fieldsDynamicAttr, sharedDataAttrList);
              }, (reason) => {
                console.log("Cancel button is pressed");
                return;
              });
            } else {
              this.proceedToSubmit(newFieldObj, fields, fieldsDynamicAttr, sharedDataAttrList);
            }
          }
        });
      } else {
        this.proceedToSubmit(newFieldObj, fields, fieldsDynamicAttr, sharedDataAttrList);
      }
    } else if (newFieldObj.actions && newFieldObj.actions.length) {
      newFieldObj.actions.forEach(action => {
        if (action.actionType === ACTION_TYPE.RELOAD_COMPONENT_DATA
          || action.actionType === ACTION_TYPE.CLEAR_COMPONENT_DATA
          || action.actionType === ACTION_TYPE.SHOW_COMPONENT_LOADER
          || action.actionType === ACTION_TYPE.HIDE_COMPONENT_LOADER) {
          this.utilsService.iterateArrayMap(fields, (field, key, index) => {
            if (field.baseProperties.name === action.componentName) {
              field.action.emit(action);
            }
          });              
        }
      });
    } else if (newFieldObj.customRedirect) {
      const navigateUrl = this.evaluateUrlParams(newFieldObj.customRedirect);      
      this.router.navigate([navigateUrl], { relativeTo: this.route });
    }
  }

  //Modify URL if it is in array format 
  //e.g. i/p: ["/viewBusinessProfile/", "$SHARED_DATA:shopId"] o/p: 'viewBusinessProfile/5fa0286b544cf55f88ca56c6' 
  //Supports: $CURRENT_URL, $SHARED_DATA and normal string  
  public evaluateUrlParams (urlArr: Array<String>) {
    let navigateUrl = "";
    urlArr.forEach( obj => {
      if (typeof obj === 'string') {
        if (obj === '$CURRENT_URL') {
          navigateUrl += this.router.url;
        } else if (obj.startsWith('$SHARED_DATA')) {
          const sharedDataAccessor = obj.split(':')[1];
          navigateUrl += this.appData.getSharedDataByAccessor(sharedDataAccessor);
        } else {
          navigateUrl += obj;
        }          
      }
    });
    return navigateUrl;
  }

  private proceedToSubmit(newFieldObj: any, fields?: Map<string, Array<Field<any>>>, fieldsDynamicAttr?: Map<string, Array<FieldDynamicAttributes>>,
    sharedDataAttrList?: string[]) {
    this.store.dispatch(pageActions.ShowLoader());
    this.callApi(newFieldObj.submitFormData.fieldObj.apiConfig, fields, fieldsDynamicAttr).pipe(
      finalize(() => {
        newFieldObj.submitFormData.state.loading = false;
      }),
    ).subscribe((res) => {
      this.store.dispatch(pageActions.HideLoader());
      const sharedData = this.appData.getAllSharedData();
      this.store.dispatch(pageActions.CheckForUpdate({ sharedDataAttributeList: sharedDataAttrList, sharedData: sharedData }));
    }, (err) => {
      console.log(err);
      this.store.dispatch(pageActions.HideLoader());
      if (newFieldObj.submitFormData.state?.hasOwnProperty("intervalPromise") && newFieldObj.submitFormData.state.intervalPromise !== null) {
        clearInterval(newFieldObj.submitFormData.state.intervalPromise);
        newFieldObj.submitFormData.state.intervalPromise = null
        newFieldObj.submitFormData.state.showTimer = false;
        if (newFieldObj.submitFormData.fieldObj.onClick 
          && newFieldObj.submitFormData.fieldObj.onClick.timer 
          && newFieldObj.submitFormData.fieldObj.onClick.timer.enableAfterExpire) {
          newFieldObj.submitFormData.state.disabled = false;
        }
      }
    });
  }
  private getFieldsDictionary (fields ) {
    const fieldsDictionary = new Dictionary<string>();
    iterateArrayMap(fields, (field: Field<any>) => {
      fieldsDictionary.Add(field.baseProperties.name, field.value);
    });
    return fieldsDictionary;
  }
}
