import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { TableElement } from '../../form/interfaces/table-element';
import { ViewComponent } from '@elements/view/view.component';
import { FieldDynamicAttributes } from '../../form/interfaces/field-dynamic-attributes';
import * as _ from "lodash";
import { UrlConfiguration } from '../../models/url-configuration';
import { Observable } from 'rxjs';
import { ACTION_TYPE } from '../../models/events';

@Component({
  selector: 'hmi-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss']
})
export class TableComponent  extends ViewComponent implements OnInit {

  @Input() fieldObj: TableElement;
  @Input() dynamicAttributes: FieldDynamicAttributes;
  @Input() customApiCall: (searchConfig: UrlConfiguration, CUSTOM_FIELD_OBJECT?: any) => Observable<any>;

  columnLabels: Array<string> = [];
  columnAccessors: Array<string> = [];
  subscription: any;

  constructor() {
    super();    
  }

  ngOnInit(): void {
    if (this.fieldObj.dataConfig) {
      this.loadData();
    }
    if (this.fieldObj.colNameAccessorMap) {
      this.fieldObj.colNameAccessorMap.forEach( nameAccessorMap => {
        this.columnLabels.push(nameAccessorMap.colName);
        this.columnAccessors.push(nameAccessorMap.accessor);
      });
    }
    this.subscription = this.fieldObj.action.subscribe(actionObj => {
      if (actionObj.actionType === ACTION_TYPE.RELOAD_COMPONENT_DATA) {
        this.loadData();
      }      
    });
  }

  getData(dataObject, accessKey): any {
    return _.get(dataObject, accessKey);
  }

  onCellClick(actionObj, rowData): any {
    if (actionObj && actionObj.name && actionObj.name.toLowerCase() === 'delete'
      && actionObj.apiConfig && actionObj.apiConfig.url) {
      this.customApiCall(actionObj.apiConfig, rowData).subscribe(() => { 
        let dynamicAttributes = _.cloneDeep(this.dynamicAttributes);
        if (actionObj.apiConfig.onSuccess && actionObj.apiConfig.onSuccess.deleteTableRow) {
          dynamicAttributes.value = _.reject(dynamicAttributes.value, rowData);
        }
        this._dataChange.emit({ dynamicData: dynamicAttributes });
      })
    }
  }

  loadData(): void {
    this.customApiCall(this.fieldObj.dataConfig).subscribe( data => { 
      this.dynamicAttributes.value = data;
      let dynamicAttributes = _.cloneDeep(this.dynamicAttributes);
      this._dataChange.emit({ dynamicData: dynamicAttributes });
    });
  }

  onRefresh(): void {
    this.loadData();
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
