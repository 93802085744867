import { SectionLayout } from "./sectionLayout";
import { SectionTypeEnum } from "./sectionTypeEnum"

export class Section {
    id: string;
    type: SectionTypeEnum;
    layout: SectionLayout;
    sectionList: Array<Section> = [];
    constructor(options: {
        id: string,
        type?: string,
        layout: any,
        sectionList?: any[]
    }) {
        if(options.id === undefined || options.id === null || options.id.trim() === "") {
            console.error("id of section cannot be null");
        }
        this.id = options.id;
        this.layout = new SectionLayout(options.layout);
        this.type = this.getSectionType(options.type);
        if(options.sectionList !== undefined) {
            options.sectionList.forEach(section => this.sectionList.push(new Section(section)));
        }
        
    }

    private getSectionType(sectionType: string): SectionTypeEnum {
        switch(sectionType) {
            case SectionTypeEnum.form:
                return SectionTypeEnum.form;
            case SectionTypeEnum.tab:
                return SectionTypeEnum.tab;
            case SectionTypeEnum.popover:
                return SectionTypeEnum.popover;
            case SectionTypeEnum.modal:
                return SectionTypeEnum.modal;
            case SectionTypeEnum.content:
            default:
                return SectionTypeEnum.content;
        }
    }
}