export enum FieldType {
  text="text",
  email="email",
  url="url",
  tel="tel",
  textarea="textarea",
  password="password",
  dropdown="dropdown",
  button="button",
  submit="submit",
  reset="reset",
  staticText="staticText",
  dropdownMenu="dropdownMenu",
  typeahead="typeahead",
  radio="radio",
  table="table",
  date="date",
  time="time",
  datetime="date-time",
  dateInline="date-inline",
  link="link",
  number="number",
  navItemGroup="navItemGroup",
  navTree="navTree",
  checkbox="checkbox",
  staticIcon="staticIcon",
  staticImage="staticImage",
  navMenu="nav-menu",
  tab="tab",
  dynamicField = "dynamicField",
  file = "file"
}
