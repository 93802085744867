<div class="container-fluid section-container" [ngClass]="section.layout.css && section.layout.css.customClassName" *ngIf="section.type !== 'tab' && section.type !== 'form' && section.type !== 'modal'">
  <ng-template *ngTemplateOutlet="sectionRow"></ng-template>
</div>

<form class="container-fluid section-container section-container__form" [ngClass]="section.layout.css && section.layout.css.customClassName" [formGroup]="formGroupContainer" *ngIf="section.type === 'form'">
  <ng-template *ngTemplateOutlet="sectionRow"></ng-template>
</form>

<div [ngClass]="section.layout.css && section.layout.css.customClassName" *ngIf="section.type === 'modal'">
  <ng-template #modalRef let-modalVar>
    <div class="modal-body container-fluid section-container">
      <ng-template *ngTemplateOutlet="sectionRow"></ng-template>
    </div>
    <button
      type="button"
      class="section-container__modal-cross"
      aria-label="Close"
      (click)="modalVar.dismiss('Cross click')"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </ng-template>
</div>

<div [ngClass]="{ 'd-flex': section.layout.orientation === 'vertical' }" [ngClass]="section.layout.css && section.layout.css.customClassName" *ngIf="section.type === 'tab'">
  <ul ngbNav #navvar="ngbNav" [(activeId)]="activeTab" class="section__tab-container" [ngClass]="section.layout.orientation === 'vertical' ? 'nav-pills': 'nav-tabs'" orientation="{{section.layout.orientation}}">
    <li [ngbNavItem]="fields[section.id][i].baseProperties.id" [ngClass]="fields[section.id] && fields[section.id][i].layoutProperties && fields[section.id][i].layoutProperties.css && fields[section.id][i].layoutProperties.css.customClassName" *ngFor="let childSection of section.sectionList; index as i; trackBy: trackBySection">
      <a ngbNavLink>{{ (fields[section.id] && fields[section.id][i].baseProperties && fields[section.id][i].baseProperties.label) || 'Default Tab Label' }}</a>
      <ng-template ngbNavContent>
        <app-section id="{{childSection.id}}" [section]="childSection" [fields]="fields" [fieldsDynamicAttr]="fieldsDynamicAttr" 
        [customApiCall]="customApiCall" (dataChangeToParent)="sendValueToParent($event)"></app-section>
      </ng-template>
    </li>
  </ul>

  <div [ngbNavOutlet]="navvar"></div>
</div>

<ng-template #sectionRow >
  <div class="row section-container__parent" *ngIf="!containSectionChild && fieldsDynamicAttr">
    <div class="section-container__column" ngClass="{{columnStructure.classes.xs[(columnStructure.classes.xs.length === 1) ? 0 : (i % columnStructure.classes.xs.length)]}}
     {{columnStructure.classes.sm[(columnStructure.classes.sm.length === 1) ? 0 : (i % columnStructure.classes.sm.length)]}} 
     {{columnStructure.classes.md[(columnStructure.classes.md.length === 1) ? 0 : (i % columnStructure.classes.md.length)]}} 
     {{columnStructure.classes.lg[(columnStructure.classes.lg.length === 1) ? 0 : (i % columnStructure.classes.lg.length)]}} 
     {{ (fieldsDynamicAttr[section.id][i].visibleValue && fields[section.id].length > 0) ? 'section-container__field' : '' }}"
        *ngFor="let field of fields[section.id]; let i = index; trackBy: trackByFn" [hidden]="!fieldsDynamicAttr[section.id][i].visibleValue">
        <!--list of widgets will go here-->
        <!--dataChange event has to be added for all widgets to handle events-->
        <hmi-char-text id="{{field.baseProperties.id}}" *ngIf="['text', 'email','url', 'tel', 'textarea', 'password', 'number', 'hidden'].indexOf(field.baseProperties.type) !== -1"
          (dataChange)="sendValueToParent($event)"
          [fieldObj]="field" [dynamicAttributes]="fieldsDynamicAttr[section.id][i]"></hmi-char-text>
        <hmi-dropdown id="{{field.baseProperties.id}}" *ngIf="field.baseProperties.type === 'dropdown'" 
          [fieldObj]="field" [dynamicAttributes]="fieldsDynamicAttr[section.id][i]"
          [customApiCall]="customApiCall" [optionList]="field.optionList" 
          (dataChange)="sendValueToParent($event)"></hmi-dropdown>
        <hmi-button id="{{field.baseProperties.id}}" *ngIf="['button', 'submit', 'reset', 'link'].indexOf(field.baseProperties.type) !== -1" 
          [fieldObj]="field" [dynamicAttributes]="fieldsDynamicAttr[section.id][i]"
          (dataChange)="sendValueToParent($event)"></hmi-button>
        <hmi-static-text id="{{field.baseProperties.id}}" *ngIf="field.baseProperties.type === 'staticText'" 
          [fieldObj]="field" [dynamicAttributes]="fieldsDynamicAttr[section.id][i]"
          (dataChange)="sendValueToParent($event)"></hmi-static-text>
        <hmi-static-icon id="{{field.baseProperties.id}}" *ngIf="field.baseProperties.type === 'staticIcon'" 
          [fieldObj]="field" [dynamicAttributes]="fieldsDynamicAttr[section.id][i]"
          (dataChange)="sendValueToParent($event)"></hmi-static-icon>
        <hmi-static-image id="{{field.baseProperties.id}}" *ngIf="field.baseProperties.type === 'staticImage'" 
          [fieldObj]="field" [dynamicAttributes]="fieldsDynamicAttr[section.id][i]"
          (dataChange)="sendValueToParent($event)"></hmi-static-image>
        <hmi-dropdown-menu id="{{field.baseProperties.id}}" *ngIf="field.baseProperties.type === 'dropdownMenu'" 
          [fieldObj]="field" [dynamicAttributes]="fieldsDynamicAttr[section.id][i]" 
          [optionList]="field.optionList"
          (dataChange)="sendValueToParent($event)"></hmi-dropdown-menu>
        <hmi-typeahead id="{{field.baseProperties.id}}" *ngIf="field.baseProperties.type === 'typeahead'" 
          [fieldObj]="field" [dynamicAttributes]="fieldsDynamicAttr[section.id][i]"
          [customApiCall]="customApiCall"
          (dataChange)="sendValueToParent($event)"></hmi-typeahead>
        <hmi-radio id="{{field.baseProperties.id}}" *ngIf="field.baseProperties.type === 'radio'" 
          [fieldObj]="field" [dynamicAttributes]="fieldsDynamicAttr[section.id][i]"
          [optionList]="field.optionList" (dataChange)="sendValueToParent($event)"></hmi-radio>
        <hmi-checkbox id="{{field.baseProperties.id}}" *ngIf="field.baseProperties.type === 'checkbox'" 
          [fieldObj]="field" [dynamicAttributes]="fieldsDynamicAttr[section.id][i]"
          [optionList]="field.optionList" (dataChange)="sendValueToParent($event)"></hmi-checkbox>
        <hmi-nav-menu id="{{field.baseProperties.id}}" *ngIf="field.baseProperties.type === 'nav-menu'" 
          [fieldObj]="field" [dynamicAttributes]="fieldsDynamicAttr[section.id][i]"
          (dataChange)="sendValueToParent($event)"></hmi-nav-menu>
        <hmi-table id="{{field.baseProperties.id}}" *ngIf="field.baseProperties.type === 'table'" 
          [fieldObj]="field" [dynamicAttributes]="fieldsDynamicAttr[section.id][i]"
          [customApiCall]="customApiCall"
          (dataChange)="sendValueToParent($event)"></hmi-table>
        <hmi-date-time id="{{field.baseProperties.id}}" *ngIf="['date', 'time', 'date-time', 'date-inline'].indexOf(field.baseProperties.type) !== -1" 
          [fieldObj]="field" [dynamicAttributes]="fieldsDynamicAttr[section.id][i]"
          [customApiCall]="customApiCall"
          (dataChange)="sendValueToParent($event)"></hmi-date-time>
        <hmi-nav-item-group id="{{field.baseProperties.id}}" *ngIf="field.baseProperties.type === 'navItemGroup'"  
          [fieldObj]="field" [dynamicAttributes]="fieldsDynamicAttr[section.id][i]"></hmi-nav-item-group> 
        <!-- <hmi-dynamic-creator *ngIf="field.baseProperties.type === 'dynamic-element'"></hmi-dynamic-creator>   -->
        <hmi-dynamic-creator id="{{field.baseProperties.id}}" *ngIf="field.baseProperties.type === 'dynamicField'"  
          [fieldObj]="field" [dynamicAttributes]="fieldsDynamicAttr[section.id][i]"
          [customApiCall]="customApiCall"
          (dataChange)="sendValueToParent($event)"></hmi-dynamic-creator>
        <hmi-file-upload id="{{field.baseProperties.id}}" *ngIf="field.baseProperties.type === 'file'"
          (dataChange)="sendValueToParent($event)"
          [fieldObj]="field" [dynamicAttributes]="fieldsDynamicAttr[section.id][i]"></hmi-file-upload>
    </div>
  </div>
  <div class="row" *ngIf="containSectionChild">
    <div ngClass="{{columnStructure.classes.xs[i % columnStructure.noOfColumn]}} {{columnStructure.classes.sm[i % columnStructure.noOfColumn]}} {{columnStructure.classes.md[i % columnStructure.noOfColumn]}} {{columnStructure.classes.lg[i % columnStructure.noOfColumn]}}"
        *ngFor="let childSection of section.sectionList; index as i; trackBy: trackBySection">
      <app-section id="{{childSection.id}}" [section]="childSection" [fields]="fields" [fieldsDynamicAttr]="fieldsDynamicAttr" 
      [customApiCall]="customApiCall" (dataChangeToParent)="sendValueToParent($event)"></app-section>
    </div>
  </div>
</ng-template>

