<div class="modal-header">
    <h4 class="modal-title" id="modal-title">{{  data.message.title }}</h4>
    <button type="button" class="close" aria-describedby="modal-title" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">{{ data.message.body }}</div>
  <div class="modal-footer">
    <button type="button" class="btn btn-primary" (click)="modal.close('Ok click')">{{ data.yesButtonLabel }}</button>
    <button type="button" class="btn btn-outline-secondary" (click)="modal.dismiss('cancel click')">{{ data.noButtonLabel }}</button>
  </div>