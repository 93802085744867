import { Injectable } from '@angular/core';
import { Route } from '@angular/router';
import * as _ from "lodash";

@Injectable({
  providedIn: 'root'
})
export class CommonService {

  currentRoute = {
    params: {},
    queryParams: {}
  };

  setRouteParams(params: any) {
    this.currentRoute.params = params;
  }

  setRouteQueryParams(queryParams: any) {
    this.currentRoute.queryParams = queryParams;
  }

  getRouteData(): any {
    return this.currentRoute;
  }

  isMobileDevice(): boolean {
    return !!(navigator.userAgent.match(/Android/i)
          || navigator.userAgent.match(/webOS/i)
          || navigator.userAgent.match(/iPhone/i)
          || navigator.userAgent.match(/iPad/i)
          || navigator.userAgent.match(/iPod/i)
          || navigator.userAgent.match(/BlackBerry/i)
          || navigator.userAgent.match(/Windows Phone/i)
          || (window.innerWidth < 768));
  }

  /**
   * @param fullUrl
   * URL should be in the format '/path/subPath/subSubPath'
   * No validations of url format done here
   */
  splitUrl(fullUrl: String): Array<String> {
    let urlArray = fullUrl.split('/');
    if (urlArray && urlArray.length && urlArray[0] === "") {
      urlArray.shift();
    }
    return urlArray;
  }

  findRouteConfByUrl(config: Array<Route> | Array<any>, url: string): any {
    url = decodeURIComponent(url);
    const splitUrlAtQueryParam = url.split('?');
    const currentPathArr = this.splitUrl(splitUrlAtQueryParam[0]);
    return _.find(config, (obj) => {
      const pathArr = (obj.path && obj.path.split('/')) || [];
      return this.matchConfigWithPath(pathArr, currentPathArr);
    });
  }

  getRoutableObject(config: Array<Route> | Array<any>, url: string): any {
    url = decodeURIComponent(url);
    const splitUrlAtQueryParam = url.split('?');
    const currentPathArr = this.splitUrl(splitUrlAtQueryParam[0]);
    if (splitUrlAtQueryParam.length < 2) {
      return { url: currentPathArr };
    } else {
      const queryParamStringList = splitUrlAtQueryParam[1].split('&');
      const queryParams = {};
      queryParamStringList.forEach(queryParamString => {
        const paramKeyValue = queryParamString.split('=');
        queryParams[paramKeyValue[0]] = paramKeyValue[1];
      });
      return { url: currentPathArr, queryParams };
    }
  }

  matchConfigWithPath(pathArr, currentPathArr) {
    let isPathMatched = true;
    if (pathArr.length !== currentPathArr.length) {
      return false;
    }
    for (let index = 0; index < pathArr.length; index++) {
      let currentActualPath = currentPathArr[index];
      if (currentActualPath.indexOf('?') !== -1) {
        currentActualPath = currentActualPath.split('?')[0];
      }
      if (currentActualPath !== pathArr[index] && !pathArr[index].startsWith(':')) {
        isPathMatched = false;
        break;
      }
      if (currentActualPath !== currentPathArr[index]) {
        break;
      }
    }
    return isPathMatched;
  }

  findApiConfByUrl(config, url: string): any {
    const currentPathArr = this.splitUrl(url);
    return _.find(config, (obj, key) => {
      const pathArr = (key && key.split('/')) || [];
      return this.matchConfigWithPath(pathArr, currentPathArr);
    });
  }
}
