<div *ngFor="let message of appMessenger.messages">
  <ngb-toast
    *ngIf="message.type === 'info'; else elseBlock"
    class="text-light"
    [ngClass]="{'bg-danger': (message.type === 'error'), 'bg-success': (message.type === 'success')}"
    [header]="message.header" [autohide]="true" [delay]="message.delay || 1000000"
    (hide)="appMessenger.removeMessage(message)"
  >{{message.body}}</ngb-toast>
  <ng-template #elseBlock>
    <ngb-alert [type]="message.type" (close)="appMessenger.removeMessage(message)">{{ message.body }}</ngb-alert>
  </ng-template>
</div>