//TODO expression should put fieldId in $FieldId$
import { Injectable } from '@angular/core';
import { FormsService } from './forms.service';
import { Parser } from 'expr-eval';
import { Optional } from '@angular/core';
import * as _ from "lodash";
import { Injector } from '@angular/core';
@Injectable({
  providedIn: 'root'
})
export class ExpressionEvaluatorService {
  parser = new Parser();
  constructor(private formsService: FormsService) {
    
   }

  public evaluateExpression(expression: any, formName: any) {
    if (expression && expression != "") {
      console.log("Parse Expression" + expression);
      const expr = this.parser.parse(expression);
      let variables = expr.variables({withMembers: true})
      let obj = {};
      variables.forEach(element => {
        let value = this.formsService.getFieldValueById(formName, element);
        value = value || "";
        _.set(obj, element,value);
      })
      return expr.evaluate(obj);
    }
    
   }

  public evaluateFieldValue(fieldIds: string,formName: any) {
    let fieldId = fieldIds.match(/(?<=\$).+?(?=\$)/g);
      if (fieldId) { //check for plain string
          return this.formsService.getFieldValueById(formName, fieldId);
      } else {
        return fieldIds;
      }
   }
}
