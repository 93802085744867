import { LayoutCss } from "./css-interface";

export class LayoutProperties {
    width?: number;
    height?: number;
    labelPlacement = "top";
    css: LayoutCss;
    orientation: string;

    constructor(height?: number,
        width?: number,
        labelPlacement?: string,
        css?: any,
        orientation?: string
        ) {
            this.width = width;
            this.height = height;
            this.labelPlacement = labelPlacement;
            this.css = css;
            this.orientation = orientation;
    }
} 