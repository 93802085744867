<div *ngIf="showAddToHomeScreen" class="app__add-to-hs">
    <div class="hs-backdrop" (click)="closeAddToHomeScreen()"></div>
    <div class="hs-content">
        <button (click)="closeAddToHomeScreen()" class="btn btn-secondary">Close</button>
        <button (click)="addToHomeScreen()" class="btn btn-primary btn-add-to-hs">Add to Home Screen</button>
    </div>
</div>
<app-message aria-live="polite" aria-atomic="true"></app-message>
<div class="page-container" *ngIf="isRouteConfigLoaded">
    <div class="full-page-loader d-flex justify-content-center align-items-center"
        *ngIf="showFullPageLoader">
        <div class="spinner-border text-info loader-placement"></div>
    </div>
    <div class="page-upper">
        <router-outlet name="header"></router-outlet>
        <router-outlet name="navbar"></router-outlet>
        <router-outlet></router-outlet>
    </div>
    <div class="page-lower">
        <router-outlet name="footer"></router-outlet>
    </div>    
</div>
