import { Component, ElementRef, Input, ViewChild, OnDestroy, AfterViewInit } from '@angular/core';
import { ViewComponent } from '../view/view.component';
import { FieldDynamicAttributes } from '../../form/interfaces/field-dynamic-attributes';
import { StaticTextField } from '../../form/interfaces/static-text-field';
import { EventAction } from '@lib-service/event.action';

@Component({
  selector: 'hmi-static-text',
  templateUrl: './static-text.component.html',
  styleUrls: ['./static-text.component.scss']
})
export class StaticTextComponent extends ViewComponent implements OnDestroy, AfterViewInit {

  @Input() fieldObj: StaticTextField;
  @Input() dynamicAttributes: FieldDynamicAttributes;

  keyArr: Array<String>;
  @ViewChild('textRef') textRef: ElementRef;
  eventSubscriptionArr: any;

  constructor(eventAction: EventAction) {
    super(eventAction);
    this.keyArr = [];
  }

  ngOnInit() {
    if (this.fieldObj.dynamicValueMap && this.fieldObj.dynamicValueMap.length) {
      for (let i = 0; i < this.fieldObj.dynamicValueMap.length; i++) {
        this.keyArr.push(this.fieldObj.dynamicValueMap[i].value);
      }
    } 
  }

  ngAfterViewInit() {
    this.eventSubscriptionArr = this.attachEventsToElement(this.fieldObj.events, this.textRef?.nativeElement, this.fieldObj);
  }

  ngOnDestroy() {
    this.eventSubscriptionArr?.forEach((subObj) => {
      subObj.unsubscribe();
    });
    this.eventSubscriptionArr = null;
  }
}
