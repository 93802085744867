import { Component, OnInit, Input, EventEmitter, Output, AfterViewChecked, ChangeDetectorRef, ElementRef, ViewChild, AfterViewInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

import { CharTextComponent, DropdownComponent, ButtonComponent, StaticTextComponent,
  DropdownMenuComponent, TypeaheadComponent, TableComponent, FileUploadComponent } from '@hmi/ui-component';
import { FormsService } from '@lib-service/forms.service';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { UrlConfiguration } from '../../models/url-configuration';
import { Observable } from 'rxjs';
import { Section } from '../../form/interfaces/section';
import { SectionTypeEnum } from '../../form/interfaces/sectionTypeEnum';
import { HMIModalService } from '@lib-service/modal.service';

@Component({
  selector: 'app-section',
  templateUrl: './section.component.html',
  styleUrls: ['./section.component.scss']
})
export class SectionComponent implements OnInit, AfterViewChecked, AfterViewInit  {
  @Input('section') inputSection: any;
  @Input('fields') fields: any;
  @Input('fieldsDynamicAttr') fieldsDynamicAttr: any;
  @Input() public customApiCall: (searchConfig: UrlConfiguration, CUSTOM_FIELD_OBJECT?: any) => Observable<any>;
  @Output('dataChangeToParent') private _dataChangeToBody = new EventEmitter<any>();
  section: Section;
  formGroupContainer: UntypedFormGroup;
  /*columnStructure: {
    noOfColumn: number,
    classes: {
      xs: Array<string>,
      sm: Array<string>,
      md: Array<string>,
      lg: Array<string>
    }
  };*/
  columnStructure: any;
  containSectionChild: boolean;
  requiredParameters: Array<String>;
  activeTab: String;
  @ViewChild('modalRef', { static: false }) private modalRef: ElementRef;

  constructor(private formService: FormsService, private changeDetector: ChangeDetectorRef, private hmiModalService: HMIModalService) {
    this.columnStructure = {};
    this.containSectionChild = false;
    this.requiredParameters = ['column', 'id'];
  }

  ngOnInit() {
    this.section = this.inputSection;
    //this.checkForRequiredParameters();
    if (this.section.type === SectionTypeEnum.form) {
      this.formGroupContainer = this.formService.createForm(this.section.id);
    }
    
    this.containSectionChild = this.section.sectionList && this.section.sectionList.length ? true: false;
    this.breakColumnForDevices();
    if (this.section.type === SectionTypeEnum.tab) {
      this.fields[this.section.id].forEach(element => {
        this.activeTab = element.defaultActive ? element.baseProperties.id : this.activeTab;
      });
    }
  }

  ngAfterViewInit() {
    if (this.section.type === SectionTypeEnum.modal) {
      this.hmiModalService.addRef(this.section.id, this.modalRef);
    }
  }

  ngAfterViewChecked() {
    this.changeDetector.detectChanges();  //This fix is required as after all fields are loaded inside form, the default form validators
    //change the form status to VALID or INVALID depending on field values and other conditions which could lead to some unchecked state.
    //This is a fix for this issue: https://github.com/angular/angular/issues/23657
  }

  private convertStringIntoNumber(value: string): number {
    let num: number = Number(value);
    if (Number.isNaN(num)) {
      console.log('section ratio number issue');
    }
    return num;
  }

  private convertColumnRatioIntoClass(arr: Array<string>, type: string): Array<string> {
    let resultArr: Array<string> = [];
    for (let index: number = 0; index < arr.length; index++) {
      resultArr[index] = type === 'xs'? "col-" + arr[index]: "col-" + type + "-" + arr[index];
    }

    return resultArr;
  }

  // private checkForRequiredParameters(): void {
  //   for (let key in this.requiredParameters) {
  //     if (!this.section.hasOwnProperty(this.requiredParameters[key])) {
  //       console.log("Missing key - " + key);
  //     }
  //   }
  // }

  private breakColumnForDevices(): void {
    if (this.section.layout.column) {
      this.columnStructure.noOfColumn = isNaN(this.section.layout.column.count) ? 1 : +this.section.layout.column.count;
      this.columnStructure.classes = {};
      if (this.section.layout.column.ratio.xs) {
        this.columnStructure.classes.xs = this.convertColumnRatioIntoClass(this.section.layout.column.ratio.xs.split(':'), 'xs');
      }

      if (this.section.layout.column.ratio.sm) {
        this.columnStructure.classes.sm = this.convertColumnRatioIntoClass(this.section.layout.column.ratio.sm.split(':'), 'sm');
      }

      if (this.section.layout.column.ratio.md) {
        this.columnStructure.classes.md = this.convertColumnRatioIntoClass(this.section.layout.column.ratio.md.split(':'), 'md');
      }

      if (this.section.layout.column.ratio.lg) {
        this.columnStructure.classes.lg = this.convertColumnRatioIntoClass(this.section.layout.column.ratio.lg.split(':'), 'lg');
      }
    }
  }

  public clickSubmit(): void {
    this.formService.getFormList();
  }

  trackByFn(index, item) {
    return item.baseProperties.id + item.expressionModified + item.valueOverride;
  }

  trackBySection(index) {
    return 'section' + index;
  }

  sendValueToParent(newFieldObj) {
    this._dataChangeToBody.emit(newFieldObj);
  }
}
