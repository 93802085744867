import { EventEmitter } from '@angular/core';
import { BaseProperties } from './base-properties';
import { LayoutProperties } from './layout-properties';
import { ActionObject } from './action-object';
import { ExpressionProperties } from './expression-properties';
import { FieldType } from './fieldType';
import { HMIEvent } from '@lib-model/events';

export class Field<T> {
  baseProperties: BaseProperties;
  layoutProperties: LayoutProperties;
  expressionProperties: ExpressionProperties;
  fieldPlacement: string;
  action: EventEmitter<any>;
  expressionModified?: boolean;
  onValueChange?: { actions: Array<ActionObject> };
  onValueClear?: { actions: Array<ActionObject> };
  _value: T;
  valueOverride?: boolean; // used for reloading component when value is assigned through backend data
  events: Array<HMIEvent>;

  constructor(
    options: {
      value?: any;
      name?: string;
      id?: string;
      type?: string;
      label?: string;
      labelPlacement?: string;
      formName?: string;
      library?: string;
      fieldPlacement?: string;
      css?: any;
      onValueChange?: { actions: Array<ActionObject> };
      onValueClear?: { actions: Array<ActionObject> };
      height?: number;
      width?: number;
      disabled?: string;
      readonly?: string;
      required?: string;
      visible?: string;
      disabledValue?: string | boolean;
      readonlyValue?: string | boolean;
      requiredValue?: string | boolean;
      visibleValue?: string | boolean;
      orientation?: string;
      baseProperties?: BaseProperties;
      layoutProperties?: LayoutProperties;
      expressionProperties?: ExpressionProperties;
      events?: Array<HMIEvent>;
    } = {}
  ) {
    // TODO: remove check after fixing all fields
    this.baseProperties = options.baseProperties
      ? new BaseProperties(
          options.baseProperties.name,
          options.baseProperties.id,
          options.baseProperties.label,
          options.baseProperties.type,
          options.baseProperties.formName,
          options.baseProperties.library,
          options.baseProperties.tooltipPlacement,
          options.baseProperties.tooltip
        )
      : new BaseProperties(
          options.name,
          options.id,
          options.label,
          options.type,
          options.formName,
          options.library
        );
    // TODO: remove check after fixing all fields
    this.layoutProperties = options.layoutProperties
      ? options.layoutProperties
      : new LayoutProperties(
          options.height,
          options.width,
          options.labelPlacement,
          options.css,
          options.orientation
        );

    // TODO: remove check after fixing all fields
    this.expressionProperties = options.expressionProperties
      ? options.expressionProperties
      : new ExpressionProperties(
          options.disabled,
          options.readonly,
          options.required,
          options.visible,
          options.disabledValue,
          options.readonlyValue,
          options.requiredValue,
          options.visibleValue
        );
    this.fieldPlacement = options.fieldPlacement;
    this.action = new EventEmitter();
    this.onValueChange = options.onValueChange;
    this.onValueClear = options.onValueClear;
    this.expressionModified = false;
    this.valueOverride = false;
    this.events = options.events;
    if (
      [
        FieldType.typeahead.toString(),
        FieldType.dropdown.toString(),
        FieldType.radio.toString(),
        FieldType.checkbox.toString(),
      ].indexOf(options.baseProperties.type) === -1
    ) {
      this.value = options.value;
    }
  }

  get value(): T | any {
    return this._value;
  }
  set value(val: T | any) {
    this._value = val;
  }
}
