import { Field } from './field';

export class StaticTextField extends Field<string> {

  isHTMLText: Boolean;
  dynamicValueMap: Array<{
    key: String,
    value: any
  }>;

  constructor(options: {
    type?: string;
    dynamicValueAccessor?: any,
    isHTMLText?: Boolean;
  }) {
    super(options);
    this.isHTMLText = options.isHTMLText;
    if (options.dynamicValueAccessor && options.dynamicValueAccessor.length) {
      this.dynamicValueMap = [];
      for (let index = 0; index < options.dynamicValueAccessor.length; index++) {
        this.dynamicValueMap.push({
          key: options.dynamicValueAccessor[index],
          value: ''
        })
      }
    }
  }
}