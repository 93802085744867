import { ElementRef, Injectable } from "@angular/core";
import { ModalDismissReasons, NgbModal } from "@ng-bootstrap/ng-bootstrap";

@Injectable({
    providedIn: 'root'
})
export class HMIModalService {
    closeModalResult = "";
    modalElementRefList: Map<String, ElementRef>;
    openModelRefList = [];
    constructor(private modalService: NgbModal) {
        this.modalElementRefList = new Map();
    }

    addRef(id: string, ref: ElementRef) {
        this.modalElementRefList.set(id, ref);
    }

    getRefById(id: string): ElementRef {
        return this.modalElementRefList.get(id);
    }

    openModal(modalSectionId: string) {
        const ref = this.getRefById(modalSectionId);
        if (ref) {
            this.closeModal(modalSectionId);
            this.openModelRefList[modalSectionId] = this.modalService.open(ref);
            this.openModelRefList[modalSectionId].result.then((result) => {
                this.closeModalResult = `Closed with: ${result}`;
                delete this.openModelRefList[modalSectionId];
            }, (reason) => {
                this.closeModalResult = `Dismissed ${this.getModalDismissReason(reason)}`;
                delete this.openModelRefList[modalSectionId];
            });
        }
    }
    
    closeModal(modalSectionId) {
        if (this.openModelRefList[modalSectionId]) {
            this.openModelRefList[modalSectionId].close();
            delete this.openModelRefList[modalSectionId];
        }
    }

    private getModalDismissReason(reason: any): string {
        if (reason === ModalDismissReasons.ESC) {
            return 'by pressing ESC';
        } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
            return 'by clicking on a backdrop';
        } else {
            return `with: ${reason}`;
        }
    }
}