import { AfterViewInit, Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ViewComponent } from '@elements/view/view.component';
import { FieldDynamicAttributes } from '../../form/interfaces/field-dynamic-attributes';
import { StaticImageField } from '../../form/interfaces/static-image-field';
import { EventAction } from '@lib-service/event.action';

@Component({
  selector: 'hmi-static-image',
  templateUrl: './static-image.component.html',
  styleUrls: ['./static-image.component.scss']
})
export class StaticImageComponent extends ViewComponent implements OnInit, OnDestroy, AfterViewInit {

  @Input() fieldObj: StaticImageField;
  @Input() dynamicAttributes: FieldDynamicAttributes;

  @ViewChild('imageRef') imageRef: ElementRef;
  eventSubscriptionArr: any;
  
  constructor(eventAction: EventAction) {
    super(eventAction);
  }

  ngOnInit(): void {
    if (this.fieldObj.accessor) {
      this.fieldObj.imageUrl = this.fieldObj.value;
    }
  }

  ngAfterViewInit() {
    this.eventSubscriptionArr = this.attachEventsToElement(this.fieldObj.events, this.imageRef?.nativeElement, this.fieldObj);
  }

  ngOnDestroy() {
    this.eventSubscriptionArr?.forEach((subObj) => {
      subObj.unsubscribe();
    });
    this.eventSubscriptionArr = null;
  }

}
