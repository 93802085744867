import { Injectable, EventEmitter, ElementRef } from '@angular/core';
import * as _ from "lodash";
import { ExpressionEvaluatorService } from './expression.evaluator.service';
import { FormsService } from './forms.service';
import { HMIAction, HMIEvent, ACTION_TYPE } from '@lib-model/events';
import { Router } from '@angular/router';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { HMIModalService } from './modal.service';

@Injectable({
    providedIn: 'root'
})
export class EventAction {
    constructor( private expressionEvaluatorService: ExpressionEvaluatorService, 
        private formsService: FormsService, private router: Router,
        private hmiModalService: HMIModalService
        ) {
      
    }

    public async handleActions(actionList: Array<HMIAction>, formName: string, fieldObj: any, dataChangeEventEmitter: EventEmitter<any>) {
        for(let action of actionList) {
            switch(action.actionType) {
              case ACTION_TYPE.MESSAGE:
                this.executeMessageAction(action, formName);
                break;
              case ACTION_TYPE.NAVIGATE:
                this.executeNavigateAction(action, formName);
                break;
              case ACTION_TYPE.OPNE_URL:
                this.executeOpenUrlAction(action, formName);
                break;
              case ACTION_TYPE.SET_FIELD:
                dataChangeEventEmitter && this.executeSetFieldAction(action, formName, dataChangeEventEmitter);
                break;
              case ACTION_TYPE.RELOAD_COMPONENT_DATA:
              case ACTION_TYPE.CLEAR_COMPONENT_DATA:
              case ACTION_TYPE.SHOW_COMPONENT_LOADER:
              case ACTION_TYPE.HIDE_COMPONENT_LOADER:
                dataChangeEventEmitter && dataChangeEventEmitter.emit({ actions: [action] });
                break;
              case ACTION_TYPE.SUBMIT:
              case ACTION_TYPE.INVOKE_API:
                dataChangeEventEmitter && this.executeSubmitAction(action, formName, fieldObj, dataChangeEventEmitter);
                //invoke api action
                break;
              case ACTION_TYPE.OPEN_MODAL:
                this.executeOpenModalAction(action);
                break;
              case ACTION_TYPE.CLOSE_MODAL:
                this.executeCloseModalAction(action);
                break;
              case ACTION_TYPE.WAIT_IN_MS:
                await this.timer(action.timer);
                break;
              case ACTION_TYPE.SET_SHARED_DATA:
                dataChangeEventEmitter && this.executeSetSharedDataAction(action, dataChangeEventEmitter);
                break;
              case ACTION_TYPE.DOWNLOAD_FILE_URI:
                dataChangeEventEmitter && this.executeDownloadFileUriAction(action, dataChangeEventEmitter);
                break;
              default:
              
            }
        }
    }

    timer = ms => new Promise(res => setTimeout(res, ms));

    public executeMessageAction(action: HMIAction, formName: String) {
        if (this.evaluateCondition(action.condition, formName)) {
            switch (action.messagetype) {
                case 'Success':
                  //action.messageText = this.
                  console.log("Success: " + action.messageText)
                  break;
                case 'Info':
                  console.log("info: " + action.messageText)
                  break;
                  case 'Warning':
                  console.log("Warning: " + action.messageText)
                  break;
                case 'Danger':
                  console.log("Danger: " + action.messageText)
                  break;
              }    
        }
    }

    public executeNavigateAction(action: HMIAction, formName: String) {
        if (action.url) {
            this.router.navigate([action.url]);
        }
    }

    public executeOpenUrlAction(action: HMIAction, formName: String) {
        if (this.evaluateCondition(action.condition, formName)) {
            window.open(action.url, action.target);
        }
    }

    public executeSetFieldAction(action: HMIAction, formName: String, dataChange: EventEmitter<any>) {
        if (this.evaluateCondition(action.condition, formName)) {
            if (action.fieldValue.indexOf("$SHARED_DATA$") !== -1) {
                const dataToSend = {
                    eventName: "SET_FIELD_WITH_SHARED_DATA",
                    action: action,
                    formName: formName
                  }
                  dataChange.emit(dataToSend);  
            } else {
                const value = this.expressionEvaluatorService.evaluateFieldValue(action.fieldValue, formName);
                this.formsService.setFieldValuebyId(action.formName || formName, action.field, value);            
            }
        }
    }

    public executeSubmitAction(action: any, formName: string, fieldObj: any, dataChange: EventEmitter<any>) {
        const dataToSend = {
            state: true,
            fieldObj: fieldObj,
            action: action.apiConfig,
            actionType: action.actionType,
            fieldsStatus: this.formsService.getAllFieldValidationStatus(formName)
          }
          dataChange.emit({ submitFormData: dataToSend });  
    }

    public executeOpenModalAction(action: HMIAction) {
        this.hmiModalService.openModal(action.target);
    }

    public executeCloseModalAction(action: HMIAction) {
        this.hmiModalService.closeModal(action.target);
    }

    public executeSetSharedDataAction(action: HMIAction, dataChange: EventEmitter<any>) {
        if (action.sharedData) {
            const dataToSend = {
                eventName: "SET_SHARED_DATA",
                sharedDataConfList: action.sharedData
              }
              dataChange.emit(dataToSend); 
        }
    }

    public executeDownloadFileUriAction(action: HMIAction, dataChange: EventEmitter<any>) {
      const dataToSend = {
        eventName: "DOWNLOAD_FILE_URI",
        action: action
      };
      dataChange.emit(dataToSend); 
    }

    private executeAPICall(apiConfig) {
        switch(apiConfig.method.toUpperCase()) {
            case 'GET':
                break;
            case 'POST': 
                break;
            case 'PATCH': 
                break;
            case 'DELETE':
                break;
            case 'PUT':
                break;
        }
    }
    
    private evaluateCondition(condition: any, formName: String) {
        let evaluateExpression = this.expressionEvaluatorService.evaluateExpression(condition.replaceAll('$', ""), formName);
        return evaluateExpression;
    }
}
