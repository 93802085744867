<nav *ngIf="sectionsList && sectionsList.length" class="navbar-light bg-light navbar-expand-md navbar-toggleable-md"
  [ngClass]="{ 'fixed-top': navPosition === 'fixed',
                'navbar-open': !isNavbarCollapsed,
                'mobile':  isMobileScreen
              }">
  <div class="navbar-container" [ngClass]="{ 'mobile':  isMobileScreen }">
    <button class="navbar-toggler hidden-sm-up" type="button"
        (click)="isNavbarCollapsed = !isNavbarCollapsed" data-target="#navbarsDefault" aria-controls="navbarsDefault"
        aria-expanded="false" aria-label="Open navigation">
        <span class="navbar-toggler-icon"></span>
    </button>
    <div [ngbCollapse]="isNavbarCollapsed" class="collapse navbar-collapse bg-light" id="navbarsDefault">
      <div class="nav-main-section">
        <div *ngFor="let section of sectionsList; trackBy: trackByFn">
          <app-section id="{{section.id}}" [section]="section" [fields]="fields" [fieldsDynamicAttr]="fieldsDynamicAttr"
          [customApiCall]="customApiCall" (dataChangeToParent)="fieldValueChange($event)"></app-section>
        </div>
      </div>
        <button class="navbar-toggler hidden-sm-up close" type="button" (click)="isNavbarCollapsed = !isNavbarCollapsed"
            data-target="#navbarsDefault" aria-controls="navbarsDefault" aria-expanded="true"
            aria-label="Toggle navigation">
            <span class="fas fa-times"></span>
        </button>
        <div class="menu-overlay navbar-toggler" *ngIf="!isNavbarCollapsed" (click)="isNavbarCollapsed = true"></div>
    </div>
</div>
</nav>