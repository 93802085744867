import { SectionRatio } from "./sectionRatio";

export class SectionColumn {
    ratio: SectionRatio;
    count: number;
    autoPlace: boolean;
    constructor(options: {
        ratio: any,
        count: number,
        autoPlace: string
    }) {
        this.ratio = new SectionRatio(options.ratio);
        this.count = options.count;
        this.autoPlace = options.autoPlace === "true";
    }
}