import { Injectable } from '@angular/core';
import * as _ from "lodash";
import { AppMessageService } from './app-message.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import * as fromRoot from '@shared/store';
import * as pageActions from '@shared/store/page/page.action';
import { Field, FieldType, UrlConfiguration } from '@hmi/ui-component';

import { UtilsService } from './utils.service';
import { SharedDataConfiguration } from '@lib-model/url-configuration';
import { EventAction } from '@lib-service/event.action';
import { ACTION_TYPE } from '@lib-model/events';

@Injectable({
  providedIn: 'root'
})
export class AppDataService {
  sharedData: any;

  constructor(private messenger: AppMessageService, private utilsService: UtilsService,
    private route: ActivatedRoute,
    private router: Router,
    private store: Store<fromRoot.State>,
    private eventActionService: EventAction) {
      this.sharedData = {};
      this.setDataFromLocalStorageOnPageLoad();
  }

  private setDataFromLocalStorageOnPageLoad(): void {
    const self = this;
    Object.keys(localStorage).forEach(key => {
      const sessionVal = localStorage.getItem(key);
      let actualVal;
      try { 
        actualVal = JSON.parse(sessionVal); 
      } catch (e) { 
        actualVal = sessionVal;
      }

      self.sharedData[key] = actualVal;
    });
  }

  public setSharedDataByKey(data, response?): void {
    const self = this;
    data.forEach((config: SharedDataConfiguration) => {
      if (config.varName) {
        let data = "";
        if (config.responseAccessor && response) {
          if (config.responseAccessor === "$RESPONSE$") {
            data = response;
          } else {
            data = _.get(response, config.responseAccessor);
            if (data !== undefined) {
              data = _.get(response, config.responseAccessor);
            }
          }
        } else if (config.staticData) {
          data = config.staticData;
        } else if (config.responseAccessor?.indexOf("$SHARED_DATA$.") !== -1) {
          const keyAccessor = config.responseAccessor.split("$SHARED_DATA$.");
          if (keyAccessor.length > 1) {
            data = _.get(self.sharedData, keyAccessor[1]);
          }
        }
        self.sharedData[config.varName] = data;
        if (config.cache) {
          let valInString = self.sharedData[config.varName];
          if (_.isObject(valInString)) {
            valInString = JSON.stringify(valInString);
          }
          localStorage.setItem(config.varName, valInString);
        }
      }
    });
  }

  public setFieldDataByKeyAndCheckForUpdate(data, response): void {
    const self = this;
    data.forEach(config => {
      if (config.id && config.responseAccessor) {
        const value = _.get(response, config.responseAccessor);
        if (value !== undefined) {
          self.store.dispatch(pageActions.UpdateFieldValue({ id: config.id, value: value, isValueOverride: true }));
        }
      } else if (config.id && config.hasOwnProperty("staticValue")) {
        self.store.dispatch(pageActions.UpdateFieldValue({ id: config.id, value: config.staticValue, isValueOverride: true }));
      }
    });
    data.forEach(config => {
      if (config.id) {
        self.store.dispatch(pageActions.CheckForUpdate({ field: null, sharedData: _.cloneDeep(self.sharedData), id: config.id }));
      }
    });
  }

  public clearLocalStorageAndSharedData(keyArr: Array<string>): void {
    keyArr.forEach(key => {
      localStorage.removeItem(key);
      if(this.sharedData[key]) {
        delete this.sharedData[key];
      };
    })
  }

  public getSharedDataByAccessor(accessor: string): any {
    let val = null;
    try {
      val = _.get(this.sharedData, accessor);
    } catch (e) {
      console.log("Invalid Shared Data Key - " + accessor);
    }
    return val;
  }

  public getAllSharedData(): any {
    return _.cloneDeep(this.sharedData);
  }

  public onFormSubmitFailure(error, urlConfig, fields) {
    if (urlConfig.onError) {
      if (urlConfig.onError.showErrorMessage) {
        this.messenger.showMessage(null, error, 'error', urlConfig.onError.keepOnRouteChange ? true : false);
      }
      if (urlConfig.onError.clearSharedData && urlConfig.onError.clearSharedData.length) {
        this.clearLocalStorageAndSharedData(urlConfig.onError.clearSharedData);
      }
      if (urlConfig.onError.pageUrl) {
        this.router.navigate([urlConfig.onError.pageUrl], { relativeTo: this.route });
      }
    }
  }

  public onFormSubmitSuccess(res: any, apiConfig: UrlConfiguration, fields?: Map<string, Array<Field<any>>>) {
    if (apiConfig.onSuccess && apiConfig.onSuccess.clearSharedData && apiConfig.onSuccess.clearSharedData.length) {
      this.clearLocalStorageAndSharedData(apiConfig.onSuccess.clearSharedData);
    }
    if (apiConfig.onSuccess && apiConfig.onSuccess.sharedData && apiConfig.onSuccess.sharedData.length) {
      this.setSharedDataByKey(apiConfig.onSuccess.sharedData, res);
    }
    if (apiConfig.onSuccess && apiConfig.onSuccess.fieldData && apiConfig.onSuccess.fieldData.length) {
      this.setFieldDataByKeyAndCheckForUpdate(apiConfig.onSuccess.fieldData, res);
    }
    if (apiConfig.onSuccess && apiConfig.onSuccess.showSuccessMessage 
      && ((res != null && res.message) || (apiConfig.onSuccess && apiConfig.onSuccess.message))) {
      this.messenger.showMessage('Success', (res != null && res.message) ? res.message : apiConfig.onSuccess.message, "success");
    }   
    if (fields && apiConfig.onSuccess && apiConfig.onSuccess.actions && apiConfig.onSuccess.actions.length) {
      //TODO: Needs to be handled for all actions
      apiConfig.onSuccess.actions.forEach(action => {
        if (action.actionType === ACTION_TYPE.RELOAD_COMPONENT_DATA) {
          this.utilsService.iterateArrayMap(fields, (field, key, index) => {
            if (field.baseProperties.name === action.componentName) {
              field.action.emit(action);
            }
          });
        }
        if (action.actionType === ACTION_TYPE.SET_FIELD) {
          this.utilsService.iterateArrayMap(fields, (field, key, index) => {
            if (field.baseProperties.id === action.field?.replace(/\$/g, "")) {
              if(field.baseProperties.type === FieldType.dynamicField) {
                let data;
                if (action.fieldValue === "$RESPONSE$" || action.fieldValue === "" ) {
                  data = {...action, data: res};
                } else {
                  const modifiedData = _.get( res, action.fieldValue);
                  data = {...action, data: modifiedData};
                }
                field.action.emit(data);
              }
            }
          });
        }
      });
      this.eventActionService.handleActions(apiConfig.onSuccess.actions, null, null, null);
    } 
    if ((res && res.pageUrl) || (apiConfig.onSuccess && apiConfig.onSuccess.pageUrl)) {
      this.router.navigate([(res && res.pageUrl) ? res.PageUrl : apiConfig.onSuccess.pageUrl], { relativeTo: this.route });
    }
  }
}
