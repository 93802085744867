import { Injectable } from '@angular/core';

import * as _ from "lodash";

@Injectable({
  providedIn: 'root'
})
export class UtilsService {

  constructor() { }

  iterateArrayMap(arrayMap: Map<string, Array<any>>, callBackFn): any {
    var newArrayMap: Map<string, Array<any>> = _.cloneDeep(arrayMap);
    Object.entries(newArrayMap).map( keyValueArray => {
      keyValueArray[1].map((obj, index, arr) => {
        arr[index] = callBackFn(obj, keyValueArray[0], index);
      });
      newArrayMap[keyValueArray[0]] = keyValueArray[1];
    });
    return newArrayMap;
  }

  iterateArrayMapAndModify(arrayMap: Map<string, Array<any>>, callBackFn): any {
    Object.entries(arrayMap).map( keyValueArray => {
      keyValueArray[1].forEach((obj, index) => {
        return callBackFn(obj, keyValueArray[0], index);
      });
      arrayMap[keyValueArray[0]] = keyValueArray[1];
    });
    return arrayMap;
  }
}
