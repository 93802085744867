import { InterceptorConfiguration, LinkTag, MetaTag } from "@lib-model/app-interfaces";
import { UrlConfiguration } from "@lib-model/url-configuration";
import { LayoutProperties } from "./layout-properties";

export class AppConfigurationProperties {
    title?: string; // If page title layoutProperties not defined then App level properties will be applied
    metaTags?: Array<MetaTag>;
    linkTags?: Array<LinkTag>;
    layoutProperties?: LayoutProperties; // If page level layoutProperties not defined then App level properties will be applied
    ifPageNotFound?: string;
    apiConfigurations?: Array<UrlConfiguration>;
    interceptorConfigurations?: Array<InterceptorConfiguration>;

    constructor(options: {
        title?: string,
        metaTags?: Array<MetaTag>,
        linkTags?: Array<LinkTag>,
        layoutProperties?: LayoutProperties,
        apiConfigurations?: Array<UrlConfiguration>,
        ifPageNotFound?: string,
        interceptorConfigurations?: Array<InterceptorConfiguration>
    }) {
            this.title = options.title || null;
            this.metaTags = options.metaTags || [];
            this.linkTags = options.linkTags || [];
            this.ifPageNotFound = options.ifPageNotFound || "";
            this.apiConfigurations = options.apiConfigurations ||  null;
            this.interceptorConfigurations = options.interceptorConfigurations || [];
            this.layoutProperties = options.layoutProperties? options.layoutProperties :
                new LayoutProperties(
                    options.layoutProperties && options.layoutProperties.height,
                    options.layoutProperties && options.layoutProperties.width,      
                    options.layoutProperties && options.layoutProperties.labelPlacement,
                    options.layoutProperties &&  options.layoutProperties.css,
                    options.layoutProperties && options.layoutProperties.orientation
                );    
    }
}