import { Component, forwardRef, EventEmitter, Output, OnDestroy } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import * as _ from "lodash";
import { EventAction } from '../../services/event.action';
import { HMIEvent, VALID_EVENTS } from '@lib-model/events';
import { debounceTime, fromEvent } from 'rxjs';
@Component({
  selector: 'hmi-view',
  template: `No UI`,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ViewComponent),
      multi: true
    }
  ]
})
export class ViewComponent implements ControlValueAccessor {

  private _value: string;
  
  // Both onChange and onTouched are functions
  onChange: any = () => { };
  onTouched: any = () => { };
  @Output('dataChange') protected _dataChange = new EventEmitter<any>();
  constructor(private eventActionService?: EventAction) {
  }

  get value() {
    return this._value;
  }

  set value(val) {
    this._value = val;
    this.onChange(val);
    this.onTouched();
  }

  // We implement this method to keep a reference to the onChange
  // callback function passed by the forms API
  registerOnChange(fn) {
    this.onChange = fn;
  }
  // We implement this method to keep a reference to the onTouched
  //callback function passed by the forms API
  registerOnTouched(fn) {
    this.onTouched = fn;
  }
  // This is a basic setter that the forms API is going to use
  writeValue(value) {
    if (value) {
      this.value = value;
    }
  }

  public attachEventsToElement(events: Array<HMIEvent>, element: any, fieldObj: any) {
    let eventInstanceArr = [];
    if (element) { 
      events?.forEach((eveObj) => {
        if (VALID_EVENTS.indexOf(eveObj.event.toLowerCase()) !== -1) {
          eventInstanceArr.push(
            fromEvent(element, eveObj.event.toLowerCase()).pipe(
              debounceTime(eveObj.debounce || 0)
            ).subscribe((e) => {
              this.eventAction(e, fieldObj, fieldObj.baseProperties.formName);
            })
          )
        }
      });
    }
    return eventInstanceArr;
  }

  //handle events
  public eventAction(event: any, fieldObj: any, formName?: string) {
    console.log("Event Triggered:" + event.type);
    let events: HMIEvent;
    if (VALID_EVENTS.indexOf(event.type) !== -1) {
      events = _.find( fieldObj.events, { event: event.type}); 
    }
    if (events) {
      this.handleEvents(events, formName || (fieldObj.baseProperties && fieldObj.baseProperties.formName), fieldObj);
    }
  }

  public async handleEvents(events: HMIEvent, formName: string, fieldObj: any) {
    if (events && events.actions){
      this.eventActionService.handleActions(events.actions, formName, fieldObj, this._dataChange);
    }
  }  
}
