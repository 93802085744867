import { Field } from "./field";
import * as moment from 'moment';
import * as _ from "lodash";
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { UrlConfiguration } from '@lib-model/url-configuration';

export class DayConfiguration {
  backgroundCss?: {
    color: string;
    backgroundColor: string;
    borderColor: string;
  };
  accessor: string;
  value: string;
}

export class DateTimeField extends Field<string> {
  displayAsText: boolean;
  _value: any;
  placeholder: string;
  accessorFormat: string;
  dataConfig?: UrlConfiguration; 
  calendarDayConfig? : Array<DayConfiguration>;
  maxDate?: NgbDateStruct;
  minDate?: NgbDateStruct;
  outsideDays?: string;
  timezone?: string;  //Timezones from moment.js
  constructor(options :{
    value?: any;
    name?: string;
    label?: string;
    type?: string;
    id?:string;    
    placeholder?: string;
    accessorFormat?: string;
    dataConfig?: UrlConfiguration; 
    calendarDayConfig? : Array<DayConfiguration>;
    maxDate?: NgbDateStruct;
    minDate?: NgbDateStruct;
    outsideDays?: string; //values - visible, hidden, collapsed
    timezone?: string;
    } = {}) {
    super(options);
    this.placeholder = options.placeholder;
    this.accessorFormat = options.accessorFormat;
    this.dataConfig = options.dataConfig;
    this.calendarDayConfig = options.calendarDayConfig;
    this.maxDate = options.maxDate;
    this.minDate = options.minDate;
    this.outsideDays = options.outsideDays;
    this.timezone = options.timezone;
  }

  get value() {
    return this._value;
  }
  set value(val: string | number | moment.Moment) {
    this._value = _.isObject(val) ? val : this.convertDateTimeToGivenFormat(val);
  }  

  convertDateTimeToGivenFormat(value): NgbDateStruct {
    let isValModified = false;
    if (value && typeof value === 'string' && this.accessorFormat) {
      isValModified = true;
      value = moment(value, this.accessorFormat);      
    } else if (value && typeof value === 'number') {
      isValModified = true;
      value = moment(new Date(value));
    }
    if (isValModified) {
      if (this.baseProperties.type === "date") {
        value = {
          day: parseInt(value.get('date')),
          month: parseInt(value.get('month')) + 1,
          year: parseInt(value.get('year'))
        }
      } else if (this.baseProperties.type === "time") {
        value = {
          hour: parseInt(value.get('hour')),
          minute: parseInt(value.get('minute')),
          second: 0
        }
      }
    }
    return value;
  }
}
