import {
  ActionReducerMap,
  MetaReducer,
  createSelector
} from '@ngrx/store';
import { environment } from '@env';
import * as fromPage from './page/page.reducer';

export interface State {
  page: fromPage.PageState
}

export const reducers: ActionReducerMap<State> = {
  page: fromPage.reducer
};

export const selectPage = (state: State) => state.page;

export const selectLoader = createSelector(
  selectPage,
  (state: fromPage.PageState) => state.loading
);

export const selectAllSectionFields = createSelector(
  selectPage,
  (state: fromPage.PageState) => state.sectionWiseFields
);

export const selectAllHeaderSectionFields = createSelector(
  selectPage,
  (state: fromPage.PageState) => state.sectionWiseHeaderFields
);

export const selectAllNavSectionFields = createSelector(
  selectPage,
  (state: fromPage.PageState) => state.sectionWiseNavFields
);

export const selectAllFooterSectionFields = createSelector(
  selectPage,
  (state: fromPage.PageState) => state.sectionWiseFooterFields
);

export const getAllFieldsDictionary = createSelector(
  selectPage,
  (state: fromPage.PageState) => {
  return fromPage.getFieldAttributeDictionary(state.sectionWiseFields);
});

export const selectSectionConfig = createSelector(
  selectPage,
  (state: fromPage.PageState) => state.sectionConfig
);

export const selectFieldDynamicAttributeState = createSelector(
  selectPage,
  (state: fromPage.PageState) => state.uiDynamicAttributeState
);

export const selectHeaderSectionConfig = createSelector(
  selectPage,
  (state: fromPage.PageState) => state.headerSectionConfig
);

export const selectHeaderFieldDynamicAttributeState = createSelector(
  selectPage,
  (state: fromPage.PageState) => state.uiHeaderDynamicAttributeState
);

export const selectNavSectionConfig = createSelector(
  selectPage,
  (state: fromPage.PageState) => state.navSectionConfig
);

export const selectNavFieldDynamicAttributeState = createSelector(
  selectPage,
  (state: fromPage.PageState) => state.uiNavDynamicAttributeState
);

export const selectFooterSectionConfig = createSelector(
  selectPage,
  (state: fromPage.PageState) => state.footerSectionConfig
);

export const selectFooterFieldDynamicAttributeState = createSelector(
  selectPage,
  (state: fromPage.PageState) => state.uiFooterDynamicAttributeState
);

export const metaReducers: MetaReducer<State>[] = !environment.production ? [] : [];
