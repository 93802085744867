import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { CoreService } from '@renderer/services/core.service';
import { UtilsService } from '@shared/services/utils.service';
import { Router, NavigationEnd } from '@angular/router';
import { Store } from '@ngrx/store';
import * as fromRoot from '@shared/store';
import * as pageActions from '@shared/store/page/page.action';
import * as _ from "lodash";
import { filter } from 'rxjs/operators';
import { AppDataService } from '@shared/services/app-data.service';
import { LayoutComponent } from '@renderer/layout/layout.component';
import { CommonService } from '@shared/services/common.service';
import { GenerateCSSService } from '@shared/services/generate-css.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent extends LayoutComponent implements OnInit {

  fieldDynamicAttrSubscription: Subscription;
  sectionListSubscription: Subscription;
  pageConfigsAndDataSubscription: Subscription;
  routerSubscription: Subscription;

  constructor(public coreService: CoreService, public utilsService: UtilsService,
    private router: Router, private store: Store<fromRoot.State>, public appData: AppDataService,
    public commonService: CommonService, public generateCSSService: GenerateCSSService) { 
      super(utilsService, coreService, appData, commonService, generateCSSService);
    this.sectionWiseFields$ = this.store.select(fromRoot.selectAllFooterSectionFields);
    this.sectionsList$ = this.store.select(fromRoot.selectFooterSectionConfig); 
    this.fieldsDynamicAttr$ = this.store.select(fromRoot.selectFooterFieldDynamicAttributeState);
    this.routerSubscription = this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe((event: NavigationEnd) => {
      console.log("New url:"+event.url);
      this.pageConfigsAndDataSubscription && this.pageConfigsAndDataSubscription.unsubscribe();
      this.sectionListSubscription && this.sectionListSubscription.unsubscribe();
      this.fieldDynamicAttrSubscription && this.fieldDynamicAttrSubscription.unsubscribe();
      this.store.dispatch(pageActions.ClearFooterConfigAndData({ payload: '' }));
      this.ngOnInit();
    });
  }

  ngOnInit() {
    this.store.dispatch(pageActions.LoadFooterConfigAndData({ segment: this.jsonType.TYPE_FOOTER, sharedData: this.appData.getAllSharedData() }));
    
    this.sectionListSubscription = this.sectionsList$.subscribe( config => {
      this.sectionsList = _.cloneDeep(config);
      this.loadSectionCSS(this.sectionsList, this.jsonType.TYPE_FOOTER);
      this.generateCSSService.applyCSSConfig();
    });
    this.fieldDynamicAttrSubscription = this.fieldsDynamicAttr$.subscribe( dynamicAttrData => {
      this.dynamicFieldsLoader(dynamicAttrData);
    });
    this.pageConfigsAndDataSubscription = this.sectionWiseFields$.subscribe((data) => {
      this.loadFieldCSS(data, this.jsonType.TYPE_FOOTER);
      this.pageDataConfigure(data);      
    }, (err) => {
      console.log("Error in loading footer"+err.toString());
    }, () => console.log('Completed'));
  }

  trackByFn(index) {
    return 'footer' + index;
  }

  ngOnDestroy() {
    this.pageConfigsAndDataSubscription.unsubscribe();
    this.sectionListSubscription.unsubscribe();
    this.fieldDynamicAttrSubscription.unsubscribe();
    this.routerSubscription.unsubscribe();
    this.store.dispatch(pageActions.ClearFooterConfigAndData({ payload: '' }));
  }

}
