import { Component, OnInit } from '@angular/core';
import { Observable, Subscription, forkJoin } from 'rxjs';
import { UtilsService } from '@shared/services/utils.service';
import * as _ from "lodash";
import { Field, FieldDynamicAttributes, FieldType } from '@hmi/ui-component';
import { EventType } from 'libs/ui-component/src/lib/models/constants';
import { CoreService } from '@renderer/services/core.service';
import { AppDataService } from '@shared/services/app-data.service';
import { CommonService } from '@shared/services/common.service';
import { Section } from 'libs/ui-component/src/lib/form/interfaces/section';
import { CssType, GenerateCSSService } from '@shared/services/generate-css.service';

@Component({
  selector: 'hmi-layout',
  template: `No UI`
})
export class LayoutComponent implements OnInit {
  sectionWiseFields$: Observable<any>;
  sectionsList$: Observable<any>;
  //pageData$: Observable<any>;
  fieldsDynamicAttr$: Observable<any>;
  pageConfigsAndDataSubscription: Subscription;
  sectionListSubscription: Subscription;
  fieldDynamicAttrSubscription: Subscription;
  pageDoesNotExist = false;
  sectionsList: Array<Section> = [];
  fields = new Map<string, Array<Field<any>>>();
  fieldsDynamicAttr: Map<string, Array<FieldDynamicAttributes>> = new Map<string, Array<FieldDynamicAttributes>>();
  jsonType = {
    TYPE_BODY: "body",
    TYPE_HEADER: "header",
    TYPE_NAV: "nav",
    TYPE_FIELD: "field",
    TYPE_SECTION: "section",
    TYPE_DATA: "data",
    TYPE_NAVBAR: "navbar",
    TYPE_FOOTER: "footer",
    TYPE_HOME: "home",
  };

  constructor(public utilsService: UtilsService, public coreService: CoreService, 
    public appData: AppDataService, public commonService: CommonService,
    public generateCSSService: GenerateCSSService) { }

  ngOnInit(): void {
  }

  dynamicFieldsLoader(dynamicAttrData): void {
    if (dynamicAttrData && Object.keys(dynamicAttrData).length) {
      if (Object.keys(this.fieldsDynamicAttr).length === 0) { //Loading for first time
        this.fieldsDynamicAttr = _.cloneDeep(dynamicAttrData);
      } else {
        this.utilsService.iterateArrayMap(dynamicAttrData, (obj, key, index) => {
          if (obj.modified && !_.isEqual(this.fieldsDynamicAttr[key][index], obj)) {
            this.fieldsDynamicAttr[key][index] = obj;
            this.fields[key][index].expressionModified = !this.fields[key][index].expressionModified;
          } else if (obj.isValueModified && !_.isEqual(this.fieldsDynamicAttr[key][index].value, obj.value)) {
            if (obj.isValueOverride) {
              this.fields[key][index].valueOverride = !this.fields[key][index].valueOverride; // used for reloading component when value is assigned through backend data
            }
            this.fieldsDynamicAttr[key][index].value = obj.value;
          }
        });
      }        
    } else {
      this.fieldsDynamicAttr = new Map<string, Array<FieldDynamicAttributes>>();
    }
    
  }

  pageDataConfigure(data): void {
    if (data && Object.keys(data).length) {          
      this.fields = _.cloneDeep(data);
      const fieldSpecificMetadata = [];
      this.utilsService.iterateArrayMapAndModify(this.fields, (obj, key, index) => {
        if ( obj.baseProperties.type === FieldType.dropdown) {
          if (obj.optionsConfig && obj.optionsConfig.url && obj.optionsConfig.fetch === EventType.ONLOAD) {
            fieldSpecificMetadata.push({
              key, 
              index, 
              data$: this.coreService.callApi(obj.optionsConfig, this.fields, this.fieldsDynamicAttr)
            });
          }
        }
        if ( obj.baseProperties.type === FieldType.staticText && obj.dynamicValueMap && obj.dynamicValueMap.length) {
          for (let i = 0; i < obj.dynamicValueMap.length; i++) {
            obj.dynamicValueMap[i].value = this.appData.getSharedDataByAccessor(obj.dynamicValueMap[i].key) || '';
          }
        }
        //NOTE: Logic for tab selection as per value in url query param "tabIds"
        //Putting tabs is not feasible in header, footer or navbar. So below logic not included there.
        if ( obj.baseProperties.type === FieldType.tab ) {
          const routeObj = this.commonService.getRouteData();
          if (routeObj && routeObj.queryParams && routeObj.queryParams.tabIds) {
            const activeTabArray = routeObj.queryParams.tabIds.split(',');
            activeTabArray.forEach(element => {
              if (element.split(':')[0] === key) {
                obj.defaultActive = element.split(':')[1] === obj.baseProperties.id;
              }
            });
          }
        }

        // The parameters in url are evaluated to values in below code.          
        if ( obj.baseProperties.type === FieldType.navItemGroup) {
          if (obj.navItems) {              
            let navItemIndex = 0;
            obj.navItems.forEach( navItem => {                           
              if (Array.isArray(navItem.navigateToPage)) {
                let navigateUrl = this.coreService.evaluateUrlParams(navItem.navigateToPage);        
                obj.navItems[navItemIndex].navigateToPage = navigateUrl;
              }                
              navItemIndex++;
            });
          }
        }
        
      });
      const fieldMetadataCalls = fieldSpecificMetadata.map(dataConfigObj => dataConfigObj.data$);
      forkJoin(fieldMetadataCalls).subscribe(metadataList => {
        fieldSpecificMetadata.forEach((dataConfigObj, outerIndex) => {
          this.fields[dataConfigObj.key][dataConfigObj.index].optionList = metadataList[outerIndex];
        });
      });
    } else {
      this.fields = new Map<string, Array<Field<any>>>();
    }
  }

  generateSectionCSS(sectionList: Array<Section>): void {
    sectionList.forEach((section: Section) => {
      this.generateCSSService.readCSSConfig((section.layout.css && section.layout.css.configuration), CssType.TYPE_SECTION, section.id);
      this.generateSectionCSS(section.sectionList);
    });
  }

  generateFieldCSS(fieldData): void {
    this.utilsService.iterateArrayMap(fieldData, (field) => {
      this.generateCSSService.readCSSConfig(field.layoutProperties.css && field.layoutProperties.css.configuration, CssType.TYPE_FIELD, field.baseProperties.id);
      if (field.optionList && Array.isArray(field.optionList) && field.optionList.length) {
        _.forEach(field.optionList, (option) => {
          this.generateCSSService.readCSSConfig(option.layoutProperties && option.layoutProperties.css && option.layoutProperties.css.configuration, CssType.TYPE_FIELD, field.baseProperties.id);
        });
      }
    });
    this.generateCSSService.applyCSSConfig();
  }

  loadSectionCSS(sectionList: Array<Section>, jsonType: string): void {
    if (sectionList && !_.isEmpty(sectionList)) {
      this.generateSectionCSS(sectionList);
      this.generateCSSService.applyCSSConfig();
    }
  }

  loadFieldCSS(fieldData: any, jsonType: string): void {
    if (fieldData && !_.isEmpty(fieldData)) {
      this.generateFieldCSS(fieldData);
    }
  }

}
