import { Field } from './field';
import * as moment from 'moment';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { FieldType } from './fieldType';

export class FieldDynamicAttributes {
    id: string;
    name: string;   //can be removed if we start using id everywhere
    required: string;
    requiredValue = false;
    readonly: string;
    readOnlyValue = false;
    disabled: string;
    disabledValue = false;
    visible: string;
    visibleValue = true;
    minDate: string;
    minDateValue: NgbDateStruct;
    maxDate: string;
    maxDateValue: NgbDateStruct;
    value: any;

    //Non-core attributes
    modified = false;
    isValueModified = false;
    isValueOverride = false;

    constructor (fieldObj: any) {
        if(fieldObj.baseProperties){
          this.id = fieldObj.baseProperties.id;
          this.name = fieldObj.baseProperties.name;
          if ([FieldType.checkbox.toString()].indexOf(fieldObj.baseProperties.type) !== -1) {
            this.value = fieldObj.value && fieldObj.value.length ? fieldObj.value : [];
          } else {
            this.value = fieldObj.value;
          }
        }
        if(fieldObj.expressionProperties){
          this.required = fieldObj.expressionProperties.required;
          this.readonly = fieldObj.expressionProperties.readOnly;
          this.disabled = fieldObj.expressionProperties.disabled;
          this.visible = fieldObj.expressionProperties.visible;
          if (this.isValueExist(fieldObj.expressionProperties.readOnlyValue)) {
            this.readOnlyValue = this.getBooleanValue(fieldObj.expressionProperties.readOnlyValue);
          }
        
          if (this.isValueExist(fieldObj.expressionProperties.requiredValue)) {
            this.requiredValue = this.getBooleanValue(fieldObj.expressionProperties.requiredValue);
          }
        
          if (this.isValueExist(fieldObj.expressionProperties.visibleValue)) {
            this.visibleValue = this.getBooleanValue(fieldObj.expressionProperties.visibleValue);
          }
        
          if (this.isValueExist(fieldObj.expressionProperties.disabledValue)) {
            this.disabledValue = this.getBooleanValue(fieldObj.expressionProperties.disabledValue);
          }
        }
        this.minDate = fieldObj.minDate;
        this.maxDate = fieldObj.maxDate;
        if(!fieldObj.baseProperties) {
          console.log("BaseProperties not present for: " + JSON.stringify(fieldObj));
        }
        this.minDateValue = this.convertDateTimeToGivenFormat(fieldObj.minDateValue, fieldObj.baseProperties.type, fieldObj.accessorFormat);
        this.maxDateValue = this.convertDateTimeToGivenFormat(fieldObj.maxDateValue, fieldObj.baseProperties.type, fieldObj.accessorFormat);
        
    }
    /*
      {
        this.id = fieldObj.baseProperties.id;
        this.name = fieldObj.baseProperties.name;
        this.required = fieldObj.expressionProperties.required;
        this.readonly = fieldObj.expressionProperties.readOnly;
        this.disabled = fieldObj.expressionProperties.disabled;
        this.visible = fieldObj.expressionProperties.visible;
        if ([FieldType.checkbox.toString()].indexOf(fieldObj.baseProperties.type) !== -1) {
          this.value = fieldObj.value && fieldObj.value.length ? fieldObj.value : [];
        } else {
          this.value = fieldObj.value;
        }
        this.minDate = fieldObj.minDate;
        this.maxDate = fieldObj.maxDate;
        this.minDateValue = this.convertDateTimeToGivenFormat(fieldObj.minDateValue, fieldObj.baseProperties.type, fieldObj.accessorFormat);
        this.maxDateValue = this.convertDateTimeToGivenFormat(fieldObj.maxDateValue, fieldObj.baseProperties.type, fieldObj.accessorFormat);
        
        if (this.isValueExist(fieldObj.expressionProperties.readOnlyValue)) {
          this.readOnlyValue = this.getBooleanValue(fieldObj.expressionProperties.readOnlyValue);
        }
    
        if (this.isValueExist(fieldObj.expressionProperties.requiredValue)) {
          this.requiredValue = this.getBooleanValue(fieldObj.expressionProperties.requiredValue);
        }
    
        if (this.isValueExist(fieldObj.expressionProperties.visibleValue)) {
          this.visibleValue = this.getBooleanValue(fieldObj.expressionProperties.visibleValue);
        }
    
        if (this.isValueExist(fieldObj.expressionProperties.disabledValue)) {
          this.disabledValue = this.getBooleanValue(fieldObj.expressionProperties.disabledValue);
        }
    }
     */

    isValueExist?(val: any): boolean {
        return [undefined, ''].indexOf(val) === -1;
    }

    getBooleanValue?(val: (string | boolean)):boolean {
        return ((typeof val === 'boolean') ? val : ((typeof val === 'string') ? val === 'true' : false));
    }

    convertDateTimeToGivenFormat(value, fieldType, dateFormat?): NgbDateStruct {
      let isValModified = false;
      if (value && typeof value === 'string' && dateFormat) {
        isValModified = true;
        value = moment(value, dateFormat);      
      } else if (value && typeof value === 'number') {
        isValModified = true;
        value = moment(new Date(value));
      }
      if (isValModified) {
        if (fieldType === "date") {
          value = {
            day: parseInt(value.get('date')),
            month: parseInt(value.get('month')) + 1,
            year: parseInt(value.get('year'))
          }
        } else if (fieldType === "time") {
          value = {
            hour: parseInt(value.get('hour')),
            minute: parseInt(value.get('minute')),
            second: 0
          }
        }
      }
      return value;
    }
}