import { Injectable } from '@angular/core';
import { AppDataService } from './app-data.service';
import { Router } from '@angular/router';
import { EvaluateExprComponent } from '@hmi/extensions';
import { AuthenticationService } from './authentication.service';
import { ConditionTypeEnum } from '@shared/enums/conditionType.enum';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService {

  private evalExpr: EvaluateExprComponent;
  constructor(public appData: AppDataService, 
    public router: Router, 
    public authenticationService: AuthenticationService) {
      this.evalExpr = new EvaluateExprComponent();
    }
  
  canActivate(route: any): boolean {
    const config = route.routeConfig.authGuardConfig;
    /* authGuardConfig object definition:
    "authGuardConfig": {
      "conditions": [{
        "type": "cookie",
        "cookieName": "accessToken",
        "if": "accessToken == undefined",
        "onConditionSuccess": "login"
      }],
      "enabled": true
    }
    conditions- list of conditions based on which route is decided
    type- type of condition. Types are defined in ConditionTypeEnum
    cookieName- name of cookie. Only valid when type=cookie
    if- condition expression based on which route is navigated
    onConditionSuccess- page to be navigated to on success condition. Every condition should have a onConditionSuccess
    */

    if (!this.authenticationService.isAuthenticated() && config.ifNotAuthorized) {
      this.router.navigate([config.ifNotAuthorized]);
      return false;
    } 
    
    if (config.conditions && config.conditions.length) {
      for (const index in config.conditions) {
        if(this.isConditionForCookie(config.conditions[index])) {
          if(this.isConditionSuccessForCookie(config.conditions[index])) {
            this.router.navigate([config.conditions[index].onConditionSuccess]);
            return false;
          }
        }
        else if (config.conditions[index].if && config.conditions[index].onConditionSuccess
          && this.evalExpr.evaluateExpression(null, this.appData.sharedData, config.conditions[index].if)) {
          this.router.navigate([config.conditions[index].onConditionSuccess]);
          return false;
        }
      }
    }
    return true;
  }

  private isConditionForCookie = (condition: any): boolean => {
    return condition.type && ConditionTypeEnum.Cookie === condition.type;
  }

  private isConditionSuccessForCookie = (condition: any) => {
    let result = true;

    const cookieName = condition.cookieName;
    const cookieValue = this.authenticationService.getCookieValue(cookieName);
    let dict = {};
    dict[cookieName] = cookieValue;
    console.log(dict);
    result = this.evalExpr.evaluateExpression(dict, null, condition.if);

    return result;
  }
}

