export class SectionRatio {
    lg: string;
    md: string;
    sm: string;
    xs: string;
    
    constructor(options: {
        lg: string,
        md: string,
        sm: string,
        xs: string
    }) {
        this.lg = options.lg;
        this.md = options.md;
        this.sm = options.sm;
        this.xs = options.xs;
    }
}