import { ErrorConfiguration, SuccessConfiguration, UrlConfiguration } from '@lib-model/url-configuration';
import {Field} from './field';

export class Button extends Field<string> {
  buttonSize: string;
  submitUrl: string;
  onSuccess: SuccessConfiguration;
  onError: ErrorConfiguration;
  validity: string;
  href: string;
  target: string;
  navigateToPage: string;
  navItems: Array<any>;
  onClick: any;
  icon: any;
  apiConfig: UrlConfiguration;
  dropdownLabel: string;

  constructor(options : {
    name?: string,
    label?: string,
    type?: string,
    id?: string,
    disabled?: string,
    disabledValue?: boolean,
    submitUrl?: string,
    apiConfig?: UrlConfiguration,
    onSuccess?: SuccessConfiguration,
    onError?: ErrorConfiguration,
    validity?: string,
    href?: string,
    target?: string,
    navigateToPage?: string,
    navItems?: Array<any>,
    onClick?: any,
    icon?: any,
    dropdownLabel?: string
  } = {}) {
    super(options);
    this.submitUrl = options.submitUrl;
    this.apiConfig = options.apiConfig;
    this.onSuccess = options.onSuccess;
    this.onError = options.onError;
    this.validity = options.validity;
    this.href = options.href;
    this.target = options.target || "_self";
    this.navigateToPage = options.navigateToPage || '';
    this.navItems = options.navItems || [];
    this.onClick = options.onClick;
    this.icon = options.icon;
    this.dropdownLabel = options.dropdownLabel || 'More';
  }
  setFocus() {
    const ele = document.getElementById(this.baseProperties.id.toString());
    if(ele) {
      ele.focus();
    }
  }
}
